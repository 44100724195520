"use strict";
import '../../style/playlists.css';
import React from 'react';
import PropTypes from 'prop-types'
import PlaylistLine from './playlist_line';
import { log } from '../general';
import { Container, Row, Col } from 'reactstrap';

class PlaylistsList extends React.Component{
    render() {
        var playlists;
        if (this.props.playlists) {
            playlists = this.props.playlists.map(playlist =>
                <PlaylistLine key={playlist.id.toString()} playlist={playlist} page={this.props.page}/>
            );
        }
        return (
            <Container>
                <Row className="show-grid">
                    {playlists}
                </Row>
            </Container>                        
        );
    }
}

PlaylistsList.propType = {
    playlists: PropTypes.array.isRequired,
    page: PropTypes.number,    
};


export default PlaylistsList;