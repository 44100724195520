"use strict";
import "../../style/search.css";
import "../../style/ad.css";
import React from 'react';
import PropTypes from 'prop-types'
import { Nav, NavLink, NavItem, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import SearchResultReviews from './search_results_reviews';
import SearchResultOthers from './search_results_others';
import SearchResultDemos from './search_results_demos';
import SearchResultNews from './search_results_news';
import SearchResultInterviews from './search_results_interviews';
import SearchResultReleases from './search_results_releases';
import SearchResultGigs from './search_results_gigs';
import SearchResultVideos from './search_results_videos';
import SearchResultPages from './search_results_pages';

import {log, isUndefinedOrEmpty} from '../general';


class SearchPage extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
          activeTab: '1'
        };        
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
    }

    render() {
        const {fetched, news, reviews, interviews, others, demos, videos, gigs, releases, pages} = this.props;
        if (!fetched) {
            return false;
        }

        var newsTabElem = undefined;
        var reviewTabElem = undefined;
        var interviewTabElem  = undefined;
        var demoTabElem = undefined;
        var otherTabElem = undefined;
        var gigsTabElem = undefined;
        var releaseTabElem = undefined;
        var videoTabElem = undefined;
        var pagesTabElem = undefined;

        var newsNavElem = undefined;
        var reviewNavElem = undefined;
        var interviewNavElem  = undefined;
        var demoNavElem = undefined;
        var otherNavElem = undefined;
        var gigsNavElem = undefined;
        var releaseNavElem = undefined;
        var videoNavElem = undefined;
        var pagesNavElem = undefined;
        var keyNumber = 1;        


        const newsTab = (keyNumber, news) => { 
            return(
                <TabPane tabId={keyNumber.toString()}>
                    <SearchResultNews results={news}/>
                </TabPane>
            )
        };

        const reviewTab = (keyNumber, reviews) => { 
            return(
                <TabPane tabId={keyNumber.toString()}>
                    <SearchResultReviews results={reviews}/>
                </TabPane>
            )
        };
        const interviewTab = (keyNumber, interviews) => { 
            return(
                <TabPane tabId={keyNumber.toString()}>
                <SearchResultInterviews results={interviews}/>
                </TabPane>
            )
        };
        const demoTab = (keyNumber, demos) => { 
            return(
                <TabPane tabId={keyNumber.toString()}>
                    <SearchResultDemos results={demos}/>
                </TabPane>
            )
        };
        const otherTab = (keyNumber, others) => { 
            return(
                <TabPane tabId={keyNumber.toString()}>
                    <SearchResultOthers results={others}/>
                </TabPane>
            )
        };
        const videoTab = (keyNumber, videos) => { 
            return(
                <TabPane tabId={keyNumber.toString()}>
                    <SearchResultVideos results={videos}/>
                </TabPane>
            )
        };
        const gigTab = (keyNumber, gigs) => { 
            return(
                <TabPane tabId={keyNumber.toString()}>
                    <SearchResultGigs results={gigs}/>
                </TabPane>
            )
        };
        const releaseTab = (keyNumber, releases) => { 
            return(
                <TabPane tabId={keyNumber.toString()}>
                    <SearchResultReleases results={releases}/>
                </TabPane>
            )
        };
        const pagesTab = (keyNumber, pages) => { 
            return(
                <TabPane tabId={keyNumber.toString()}>
                    <SearchResultPages results={pages}/>
                </TabPane>
            )
        };

        const navLink = (keyNumber, title) => {
            return (
                <NavLink
                    className={classnames({ active: this.state.activeTab === keyNumber.toString() })}
                    onClick={() => { this.toggle(keyNumber.toString()); }}
                >
                    {title}
                </NavLink>
            );
        }


        if (!isUndefinedOrEmpty(news)) {
            const title = <div className="tab-header">Uutiset<br/>({news.length})</div>;
            newsTabElem = newsTab(keyNumber, news); 
            newsNavElem = navLink(keyNumber, title);
            keyNumber = keyNumber + 1;
        }
        
        if (!isUndefinedOrEmpty(reviews)) {
            const title = <div className="tab-header">Levyarvostelut<br/>({reviews.length})</div>;
            reviewTabElem = reviewTab(keyNumber, reviews); 
            reviewNavElem = navLink(keyNumber, title);
            keyNumber = keyNumber + 1;   
        }

        if (!isUndefinedOrEmpty(interviews)) {
            const title = <div className="tab-header">Artikkelit<br/>({interviews.length})</div>;
            interviewTabElem = interviewTab(keyNumber, interviews); 
            interviewNavElem = navLink(keyNumber, title);

            keyNumber = keyNumber + 1;   
        }

        if (!isUndefinedOrEmpty(demos)) {
            const title = <div className="tab-header">Demot<br/>({demos.length})</div>;
            demoTabElem = demoTab(keyNumber, demos); 
            demoNavElem = navLink(keyNumber, title);
            keyNumber = keyNumber + 1;   
        }

        if (!isUndefinedOrEmpty(others)) {
            const title = <div className="tab-header">Muut arvostelut<br/>({others.length})</div>;
            otherTabElem = otherTab(keyNumber, others); 
            otherNavElem = navLink(keyNumber, title);
            keyNumber = keyNumber + 1;   
        }

        if (!isUndefinedOrEmpty(videos)) {
            const title = <div className="tab-header">Videot<br/>({videos.length})</div>;
            videoTabElem = videoTab(keyNumber, videos); 
            videoNavElem = navLink(keyNumber, title);
            keyNumber = keyNumber + 1;   
        }

        if (!isUndefinedOrEmpty(gigs)) {
            const title = <div className="tab-header">Keikat<br/>({gigs.length})</div>;
            gigsTabElem = gigTab(keyNumber, gigs); 
            gigsNavElem = navLink(keyNumber, title);
            keyNumber = keyNumber + 1;   
        }

        if (!isUndefinedOrEmpty(releases)) {
            const title = <div className="tab-header">Julkaisut<br/>({releases.length})</div>;
            releaseTabElem = releaseTab(keyNumber, releases); 
            releaseNavElem = navLink(keyNumber, title);
            keyNumber = keyNumber + 1;   
        }

        if (!isUndefinedOrEmpty(pages)) {
            const title = <div className="tab-header">Muut jutut<br/>({pages.length})</div>;
            pagesTabElem = pagesTab(keyNumber, pages); 
            pagesNavElem = navLink(keyNumber, title);
            keyNumber = keyNumber + 1;   
        }

        return (
            <div className="search-tab">
                <div className="search-header-fixed">
                    <Nav tabs justified>
                        <NavItem>
                            {newsNavElem}
                        </NavItem>
                        <NavItem>
                            {reviewNavElem} 
                        </NavItem>
                        <NavItem>
                            {interviewNavElem}
                        </NavItem>
                        <NavItem>
                            {demoNavElem}
                        </NavItem>
                        <NavItem>
                            {otherNavElem}
                        </NavItem>
                        <NavItem>
                            {videoNavElem}
                        </NavItem>
                        <NavItem>
                            {gigsNavElem}
                        </NavItem>
                        <NavItem>
                            {releaseNavElem}    
                        </NavItem>
                        <NavItem>
                            {pagesNavElem}    
                        </NavItem>
                    </Nav>
                </div>
                <TabContent activeTab={this.state.activeTab}>
                    {newsTabElem}
                    {reviewTabElem}
                    {interviewTabElem}
                    {demoTabElem}
                    {otherTabElem}
                    {videoTabElem}
                    {gigsTabElem}
                    {releaseTabElem}
                    {pagesTabElem}
                </TabContent>
            </div>
        );
    }   
}

SearchPage.propTypes = {
    fetched: PropTypes.bool.isRequired,
    news: PropTypes.array,
    reviews: PropTypes.array,
    others: PropTypes.array,
    demos: PropTypes.array,
    interviews: PropTypes.array,
    videos: PropTypes.array,
    releases: PropTypes.array,
    gigs: PropTypes.array,
    pages: PropTypes.array,
};


export default SearchPage;