
"use strict";
import '../../style/search.css';
import React from 'react';
import PropTypes from 'prop-types';
import { Media } from 'reactstrap';
import he from 'he';

import { log, goTo, dateConverted, dateDisplayer  } from '../general';

class SearchResultNews extends React.Component{
    render() {
        
        var results;
        if (this.props.results) {
    
            results = this.props.results.map((news, index) => {
                    const news_id = "/news/1/" + news.id;
                    const dateText = dateDisplayer(dateConverted(news.date));
                    
                    return ( 
                        <div key={index} className="search-result-line search-news">
                            {goTo(news_id,
                                <Media>
                                    <Media body>
                                        <Media heading className="media-header">
                                        {he.decode(news.topic)} 
                                        </Media>
                                        {dateText}
                                        </Media>
                                </Media>                                                             
                            )}
                            <hr/>
                        </div>
                    );
                }
            );
        }
        
        return (
            <div className="search-results">
                {results}
            </div>     
        );
    }
}

SearchResultNews.propType = {
    results: PropTypes.array.isRequired
};


export default SearchResultNews;