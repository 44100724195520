'use strict';
import '../../style/videos.css';
import React, { Component } from 'react';

class Video extends Component {
    render() {
        const url = "https://www.youtube.com/embed/" + this.props.video.url + "?ecver=2";

        return (
            <div style={{
                    position: 'relative',
                    'textAlign': 'center',
                    //width: '100%',
                    'verticalAlign': 'middle',
                    height:0,
                    paddingBottom: '56.25%',
                    //height: '56.25%',
                    display: 'block',   

                }}
            >
                <iframe
                    src={url}
                    style={{
                        position: 'fixed',
                        top: 0, 
                        left: 0, 
                        bottom: 0, 
                        right: 0,                        
                        margin:'auto',
                        width:'80%',
                        height:'80%',
                        'maxWidth':'1920p',
                        allowFullscreen:"yes",
                    }}
                />
            </div>
        );
    }
}

/*
<div style="position:relative;height:0;padding-bottom:56.25%"><iframe src="https://www.youtube.com/embed/fIfv7BKvtI8?ecver=2" style="position:absolute;width:100%;height:100%;left:0" allowfullscreen="*YES*" frameborder="0"></iframe></div>

                position:"relative",
                height:"0px",
                "paddingBottom": "50%",


    text-align: center.
    width: 100%,
    'vertical-align': middle,
    height: 100%,
    display: 'table-cell',   
*/

export default Video;
