'use strict';
import '../../style/notification.css';
import React from 'react';
import PropTypes from 'prop-types'
import classnames from 'classnames';
import {log} from '../general';

const Notification = props => {
    const classes = classnames('notification', {show: props.show});
    return <footer className={classes}>{props.text}</footer>;
};

Notification.propTypes = {
    show: PropTypes.bool.isRequired,
    text: PropTypes.text,
};

export default Notification;
