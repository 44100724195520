"use strict";
import '../../style/radios.css';
import React from 'react';
import PropTypes from 'prop-types';
import { log } from '../general';
import { Container, Row} from 'reactstrap';

class RadioList extends React.Component{
    render() {
        var radioList = undefined;
        if (this.props.radios) {
            radioList = this.props.radios.map(radio => { 
                const radioUrl = "https://www.mixcloud.com/widget/iframe/?feed=https://www.mixcloud.com/deathtoposersradio/" + radio.URL + "/&hide_cover=1&light=1"
                return (
                        <iframe className="radio-entry" width="100%" height="120" src={radioUrl} frameborder="0"></iframe>                    
                    );
                }
            );
        }
        return (
            <div className="radios-list">
                <Container>
                    <Row className="show-grid">
                        {radioList}
                    </Row>
                </Container>            

            </div>     
        );
    }
}

RadioList.propType = {
    radios: PropTypes.array.isRequired,
};


export default RadioList;