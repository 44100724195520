'use strict';
import "../../style/review.css";
import React from 'react';
import {withRouter} from 'react-router-dom';
import { RotateLoader } from 'react-spinners';
import axios from 'axios';
import ReviewsPage from '../components/reviews_page';
import PaginationComponent from '../components/pagination'
import { httpRequest, queryApiUrl, log } from '../general';
import { eqProps } from 'ramda';

class Reviews extends React.Component {

    constructor() {
        super();
        this.handleSelect = this.handleSelect.bind(this);
        this.state = { reviews: [], totalAmount: 0, currentPage: 1, numberOfReviews: 18, fetched: false, error: false };
    }

    handleSelect(eventKey) {
        const page = parseInt(eventKey.selected);
        const link = "/reviews/" + (page + 1);
        this.props.history.push(link);
        this.setState({fetched: false});
    }

    getReviewsFromApiAsync(state, props) {
        const currentPage = props.match.params.page;
        const currentPageAsNumber = parseInt(currentPage);
        const offset = (currentPageAsNumber - 1) * state.numberOfReviews;
        var left;
        if (state.totalAmount == 0)
            left = state.numberOfReviews;
        else
            left = state.totalAmount - offset;
        const limit = left >= state.numberOfReviews ? state.numberOfReviews : left;
        const urlPath = queryApiUrl('imp_albums/rows?sort=date&sort_order=DESC&limit=' + limit + '&offset=' + offset + '&in[published]=1');
        const self = this;
        // Make a request for a user with a given ID
        axios.get(urlPath)
        .then(function (response) {
            window.scrollTo(0, 0);

            self.setState({
                reviews: response.data.data, 
                totalAmount: response.data.meta.total_entries, 
                fetched: true,
                currentPage: currentPage,
             });
         })
        .catch(function (error) {
            log("Error fetching reviews");
            log(error);
            self.setState({reviews:[], fetched: true, error: true});
        });
               
    }

    shouldComponentUpdate(nextProps, nextState) {
        const currentPage = nextProps.match.params.page;
        if ( currentPage != this.props.match.params.page ) {
            this.getReviewsFromApiAsync(nextState, nextProps);
            return false; 
        }
        if (nextState.fetched == true && this.state.reviews.length > 0) {
            return true;
        } else {
            if (nextState.fetched == true && nextState.error == true) {
                return true;
            }
            this.getReviewsFromApiAsync(nextState, nextProps);
            return false;
        }        
    }

    componentWillMount() {
        this.getReviewsFromApiAsync(this.state, this.props);
        window.scrollTo(0, 0);
    }    
 
    render() {
        const currentPage = this.props.match.params.page;
        const currentPageAsNumber = parseInt(currentPage) -1;
        if (this.state.fetched == false) {
            return (
                <div className="review-page-fixed searching">
                    <RotateLoader color="#4C0001" loading={!this.state.fetched}/>
                </div>            
            )
        }
        
        return (
            <div className="review-page">
                <ReviewsPage reviews={this.state.reviews} page={currentPage}/>
                <PaginationComponent  
                    totalAmount={this.state.totalAmount}
                    numberOfElementsPerPage={this.state.numberOfReviews}
                    currentPage={currentPageAsNumber}
                    handleSelect={this.handleSelect} />
            </div>
        );
    }
}

export default withRouter(Reviews);
