
"use strict";
import '../../style/search.css';
import React from 'react';
import PropTypes from 'prop-types';
import { Media } from 'reactstrap';
import { slice } from 'ramda';

import he from 'he';

import { log, goTo, dateConverted, dateDisplayer, todayCalenderWithYear } from '../general';
import PaginationComponent from './pagination';

class SearchResultGigs extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            currentPage: 1,
            totalAmount: props.results.length,
            numberOfItems: 30,
        }
        this.handleSelect = this.handleSelect.bind(this);

    }

    handleSelect(eventKey) {
        const { results } = this.props;
        const { numberOfItems } = this.state;
        const page = parseInt(eventKey.selected);
        // log(page);
        const items = slice(page * numberOfItems, page * numberOfItems + numberOfItems, results);
        this.setState({currentPage: page, items: items});
        window.scrollTo(0,0);
    }

    componentWillReceiveProps(nextProps) {
        var items = [];
        if (nextProps.results != undefined && nextProps.results.length > 0) {
            items = slice(0, this.state.numberOfItems, nextProps.results);
        } 

        this.setState({items: items});
    }

    render() {
        const {items, totalAmount, currentPage, numberOfItems}  = this.state;
        if (items == undefined)
            return false;

        const currentPageAsNumber = parseInt(currentPage) -1;
        
        const results = items.map((gig, index) => {
                const gig_id = "/gigs/1/" + gig.id;
                const dateText = todayCalenderWithYear(gig.date);
                return (
                    <div key={index} className="search-result-line search-gigs">
                        <Media>
                            <Media left className="search-gigs-left">
                                {dateText}
                            </Media>
                            <Media body>
                                <Media heading  className="media-header">
                                    {he.decode(gig.bands)}  
                                </Media>
                                {he.decode(gig.venue)}
                            </Media>
                        </Media>                                   
                        
                        <hr/>
                    </div>
                );
            }
        );
        
        
        
        return (
            <div className="search-results">
                {results}
                <div className="search-pagination">
                    <PaginationComponent  
                        totalAmount={totalAmount}
                        numberOfElementsPerPage={numberOfItems}
                        currentPage={currentPageAsNumber}
                        handleSelect={this.handleSelect} />
                </div>
            </div>        

        );
    }
}

SearchResultGigs.propType = {
    results: PropTypes.array.isRequired
};


export default SearchResultGigs;