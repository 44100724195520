"use strict";
import '../../style/review.css';
import '../../style/share.css';
import React from 'react';
import ReactImageFallback from 'react-image-fallback';
import FontAwesome from 'react-fontawesome';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom'
import Coverflow from 'reactjs-coverflow';
import { Button, Collapse, Card, CardBody, CardHeader, CardFooter } from 'reactstrap';
import { Link } from 'react-router-dom';
import Dimensions from 'react-dimensions';
import { slice } from 'ramda';
import classnames from 'classnames';
import { log, scoreDisplayer, authorLink, facebookLink, 
         bandLink, twitterLink, pageLink, linkTo, goTo, LeftNavButton, 
         RightNavButton, createMarkup, createDangerous, spotifyLink, 
         cdonLink, xLink, deezerLink, serverAddress, improveAdsLink, 
         imageWithLink, spotifyEmbed} from '../general';
import ShareButtons from './share_buttons';
import PublishingInfo from './publishing_info';
import he from 'he';
import AdPlacer from './ad_placer';

class ReviewsDetailPage extends React.Component {
    constructor(props) {
        super(props);
        this.linkClick = this.linkClick.bind(this);
        this.toggle = this.toggle.bind(this);
        this.state = { collapse: false};
    }


    componentDidMount() {
        window.prerenderReady = true;
    }

    toggle() {
        // update state
        this.setState({
            'collapse' : !this.state.collapse,
        });        
        
    } 
    
    linkClick(index){
    };     

    render() {
        const {location, review, containerWidth} = this.props;
        const ownurl = serverAddress() + location.pathname;
        const messageToShare = "#Imperiumi arvosteli levyn " + this.props.review.performer + " - " + this.props.review.record + ": ";
        const labelLink = "/search_with_parameter/imp_albums/company/" + this.props.review.company;

        if (this.props.review == undefined) {
            return (
                <div>
                    <h3>Arvostelua ei löytynyt</h3>
                </div>
            );
        }

        const year = slice(0, 4, this.props.review.date);
        const art = "/images/albumcovers/" + year + "/" + this.props.review.coverart; 
        const performerText = he.decode(this.props.review.performer);
        const albumText = he.decode(this.props.review.record); 

        const header = () => {
            return (
                <div className="review-detail-head">
                    <div className="review-detail-head-info">
                        <div>{performerText}</div>
                        <div className="review-name">{albumText}</div>        
                        <div className="company">
                        <Link to={labelLink}>
                            {this.props.review.company}
                        </Link>
                    </div>
                    </div>
                    <div className="review-score">
                        {scoreDisplayer(review.points, 9)}
                    </div>
                </div>
            );
        };

        const authorText = authorLink(this.props.review.reviewer);
        const bandlinkText = bandLink(this.props.review.bandlink);
        const facebookText = facebookLink(this.props.review.altbandlink);
        const headerText = header(); 
        var oldReleasesSlider = undefined;
        var oldReviews = undefined;

        
        if (this.props.old_releases && this.props.old_releases.length > 0) {
            /*  ReactJS-Coverflow
            */
            const oldReleases = this.props.old_releases.map((release, index) => {
            const oldYear = slice(0, 4, release.date);
                
            const coverArt = "/images/albumcovers/" +  oldYear + "/" +  release.coverart; 
            const record = release.record;
            const link = "/reviews/1/" + release.id;
            return (
                <div key={index}>
                    {goTo(link, <img className="release-picture" src={coverArt} alt={record}/> , this.linkClick)}
                </div>);
            });

            var coverWidth;
            var coverCenter;
            if (containerWidth > 700) {
                coverWidth = "700px";
                coverCenter = Math.ceil(700 / 2);
            } else {
                coverWidth = containerWidth.toString() + 'px';
                coverCenter = Math.ceil(containerWidth / 2);
            }
            const centerPlace = Math.floor(this.props.old_releases.length / 2);
            const slider = () => (
                <div className="old-releases">        
                    <Coverflow ref="coverflow"
                        style={{width: coverWidth, height:"200px", backgroundColor: "white"}}
                        startPosition={centerPlace}
                        enableScroll={true}
                        animationSpeed={0.8}>
                            {oldReleases}                    
                        </Coverflow>                
                </div>
            )
            
            oldReleasesSlider = slider();
            oldReviews = <div className="otherReviews">Muita saman artistin arvosteluja Imperiumissa:</div>;
        }

        const newReviews = this.context.layoutState.reviews.map((tmpReview, index) => {
            return(
                <div>
                    
                    {tmpReview.performer + "-" + tmpReview.record}
                </div>
            )

        });

        const songs = this.props.review.songlist ? this.props.review.songlist.split("\n").map((item)=>item.trim()) : undefined;
        var songList;
        if (songs) {
            songList = songs.map((song,index) => {
                    if (song.length == 0 ){
                        return <br/>
                    } else {
                        return (                
                            <li key={index}>{he.decode(song)}</li>
                        )
                    }
                }
            );
        } else {
            songList = undefined;
        }   
        
        const classicText = <div className="classic-text"><span>Klassikko</span></div>
        var classic = undefined; 
        if (this.props.review.classic == 1) {

            classic = <div className="classic-div">{classicText}</div>
        }

        const mainos12 = classnames('mainos', 'paikka_12');     
        const mainos19 = classnames('mainos', 'paikka_19');     
        const mainos20 = classnames('mainos', 'paikka_20');     

        
        return (
            <div className="sisalmys">
                <div className="sisalmys-review">
                    <Card>
                        <CardHeader>
                            {headerText}
                        </CardHeader>
                        <CardBody>
                            <PublishingInfo 
                                authorName={this.props.review.reviewer}
                                date={this.props.review.date}
                            />                        
                            <div className="review-detail-top">
                                <div className="review-detail-image">
                                    <ReactImageFallback
                                        src={art}
                                        fallbackImage="/images/default_review.png"
                                        alt={performerText + " " + albumText}
                                        className="image"
                                    />
                                    {classic}
                                </div>
                            </div>

                            <div className="review-share-buttons-right">
                                <ShareButtons 
                                    width={containerWidth}
                                    performer={this.props.review.performer}
                                    message={messageToShare}
                                    currentUrl={ownurl} 
                                    bandLink={this.props.review.bandlink} 
                                    facebookHomeLink={this.props.review.altbandlink}
                                    spotifyLink={this.props.review.fireboxlink}
                                    deezerLink={this.props.review.moremusiclink}                                    
                                    cdonLink={this.props.review.cdonlink}
                                    xLink={this.props.review.levykauppaxlink}
                                    rockShirtsLink={this.props.review.performer}
                                />                                
                            </div>
                            <div className="review-detail-body">
                                {createDangerous(createMarkup(this.props.review.review))}
                                <div className="songlist-link">
                                <Button className="songlist-button" color="link" onClick={() => this.toggle()}>Kappalelista&nbsp;<FontAwesome name="fa-arrow-circle-o-down" className="fa-arrow-circle-o-down"/>
                                </Button>
                                <Collapse isOpen={this.state.collapse}>
                                    <Card>
                                        <CardBody>
                                            <ul className="song-list">
                                            {songList}
                                            </ul> 
                                        </CardBody>
                                    </Card> 
                                </Collapse>                                 
                                </div>                    
                                {oldReviews}
                                {oldReleasesSlider}
            
                                </div> 
                        </CardBody>
                    </Card>
                    <div key="ma_12" className={mainos12}>
                        <AdPlacer place={12} pageWidth={containerWidth} pageHeight={400} marginLeft={15} marginRight={15}/>  
                    </div>
                    <div key="ma_19" className={mainos19}>
                        <AdPlacer place={19} pageWidth={containerWidth} pageHeight={400} marginLeft={15} marginRight={15}/>  
                    </div>
                    <div key="ma_20" className={mainos20}>
                        <AdPlacer place={20} pageWidth={containerWidth} pageHeight={400} marginLeft={15} marginRight={15}/>  
                    </div>

                </div>
            </div>
        );
    }    
}

ReviewsDetailPage.contextTypes = {
    layoutState: PropTypes.object
};


ReviewsDetailPage.propType = {
    review: PropTypes.object.isRequired,
    old_releases: PropTypes.object,
    location: PropTypes.object,
};

export default Dimensions()(withRouter(ReviewsDetailPage));

