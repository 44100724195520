"use strict";
import '../../style/articles.css';
import React from 'react';
import PropTypes from 'prop-types'
import { Card, CardHeader, CardBody } from 'reactstrap';

import { log, createDangerous, createMarkup, authorLink, bandLink, serverAddress, dateDisplayer } from '../general';

class PageDetailPage extends React.Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        if (this.props.page  == undefined) {
            return (
                <div>
                    <h3>404: Sivua ei löytynyt</h3>
                </div>
            );
        }

        const ownurl = serverAddress() + this.props.location.url;

        const header = () => {
            return (
                <div>
                    <div className="page-detail-head">
                        <div className="page-name">
                            {this.props.page.title}
                        </div>
                    </div>

                </div>
            );
        };
        const headerText = header(); 


        const text = createMarkup(this.props.page.page_html)
        const element = createDangerous(text);
        
        return (
            <div className="page_detail">
                <Card>
                    <CardHeader>
                        {headerText}
                    </CardHeader>
                    <CardBody>
                        <div className="page-detail-body">
                            {element}
                        </div>
                    </CardBody>
                </Card>
            </div>
        );
    }    
}

PageDetailPage.propType = {
    page: PropTypes.object.isRequired,
    location: PropTypes.object
};

export default PageDetailPage;