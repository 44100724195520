"use strict";
import '../../style/profiles.less';
import React from 'react';
import { findDOMNode } from 'react-dom'
import PropTypes from 'prop-types'
import ReactImageFallback from 'react-image-fallback';
import { Link } from 'react-router-dom';
import {browserHistory} from 'react-router-dom';
import classnames from 'classnames';
import { log, profileFacebookLink, profileTwitterLink, profileInstaLink, profileLinkedInLink, profileHomepageLink, createMarkup, createDangerous } from '../general';
import { Col } from 'reactstrap';


class Profile extends React.Component{
    constructor(props, context) {
        super(props, context);
        this.handleClick = this.handleClick.bind(this);
        this.state = { clicked: false };
    }

    handleClick(e) {
        this.setState({clicked: !this.state.clicked});
    };
        

    render() {
        const {fb_link, twitter_link, insta_link, linkedin_link, homepage} = this.props.profile;
        const xlValue = 6;
        const lgValue = 6;
        const mdValue = 6;
        const smValue = 6;
        const xsValue = 12;        

        const image = "/images/profileimages/" + this.props.profile.photo;
        const iconClass = classnames({"fa fa-bars": !this.state.clicked}, {"fa fa-arrow-left": this.state.clicked});
        const cardClass = classnames("material-card Blue", {"mc-active": this.state.clicked});
        const facebook = fb_link ? profileFacebookLink(fb_link) : undefined;
        const twitter = twitter_link ? profileTwitterLink(twitter_link) : undefined;
        const instagram = insta_link ? profileInstaLink(insta_link) : undefined;
        const linkedin = linkedin_link ? profileLinkedInLink(linkedin_link) : undefined;
        const homep = homepage ? profileHomepageLink(homepage) : undefined;
        const reviewsLink = "/search_with_parameter/imp_albums|imp_demos|imp_interviews|imp_booksmovies/reviewer/" + this.props.profile.name;
        const text = createMarkup(this.props.profile.description);
        const descriptionElement = createDangerous(text);

        return (
            <Col key={this.props.profile.id} xl={lgValue} lg={lgValue} md={mdValue} sm={smValue} xs={xsValue} id={this.props.profile.name}>
                <article className={cardClass} ref={"card"}>
                    <h2>
                        <span>{this.props.profile.name}</span>
                        <strong>
                            <i className="fa fa-fw fa-star"></i>
                            {this.props.profile.position}
                        </strong>
                        <div className="details-right">
                            <Link to={reviewsLink}>
                            Jutut
                            </Link>
                        </div>
                    </h2>
                    <div className="mc-content">
                        <div className="img-container">
                            <ReactImageFallback src={image} fallbackImage="images/default_card.png" className="img-responsive" />
                        </div>
                        <div className="mc-description">
                            {descriptionElement}
                        </div>
                    </div>
                    <a className="mc-btn-action" onClick={this.handleClick}>
                        <i className={iconClass} ref="icon"></i>
                    </a>
                    <div className="mc-footer">
                        <h4>
                            Linkit
                        </h4>
                        {homep}
                        {facebook}
                        {twitter}
                        {linkedin}
                        {instagram}
                    </div>
                </article>    
            </Col>            
        );
    }
}



Profile.propType = {
    profile: PropTypes.object.isRequired
};


export default Profile;
