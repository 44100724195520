"use strict";
import '../../style/pages.css';
import React from 'react';
import PropTypes from 'prop-types'
import ReactImageFallback from 'react-image-fallback';
import { log, goTo, paragraphRemoveImagesAndVideos, periodOrMaxLength, createDangerous, createMarkup } from '../general';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Card,  CardHeader, CardFooter, CardBody, Media } from 'reactstrap';



class PageLine extends React.Component{
    constructor(props, context) {
        super(props, context);
    }

    render() {
        const xlValue = 4;
        const lgValue = 4;
        const mdValue = 6;
        const smValue = 6;
        const xsValue = 9;        

        const page_id = "/pages/" + this.props.sivu + "/" + this.props.page.id;
        const text = paragraphRemoveImagesAndVideos(this.props.page.page_html);
        const text_shorted = periodOrMaxLength(text, 300);
        const paragraphMarkup = createMarkup(text_shorted);
        const element = createDangerous(paragraphMarkup);
        

        const headerPanel = (page_link, header) => {
            return (
                <div>
                    <div className="pages-header">
                    <Link className="pages-list-header" to={ page_link }>
                        {header}
                    </Link>
                    </div>
                </div>                
            )
        }        
        const header = this.props.page.title;

        return (
            <div className="pages-body">
                <Card key={this.props.page.id}>
                    <CardHeader>
                        {headerPanel(page_id, header)}
                    </CardHeader>
                    <CardBody>
                        <div className="pages-list">
                            {element}
                        </div>
                        <Link className="pages-list-read-more" to={ page_id }>
                            Lue lisää
                        </Link>                        
                    </CardBody>
                </Card> 
            </div>
        );
    }
}

PageLine.propType = {
    page: PropTypes.object.isRequired,
    sivu: PropTypes.number,
};


export default PageLine;
