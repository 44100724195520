import React from 'react';
import PropTypes from 'prop-types'
import { Nav,  Form, FormGroup, Button, Input } from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import {log, improveAdsLink, updateFromEventValue} from '../general';

import { withRouter } from 'react-router-dom';

class SearchElement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchString:  "",
        };        
    }

    render() {
        const { searchString } = this.state;
        const inputUpdate = updateFromEventValue(this.setState.bind(this));

        const search = (e) => {
            /*log("Searching");*/
            e.preventDefault();
            const searchUrl = "/search/" + searchString;
            /*log(searchUrl);*/
            this.props.history.push(searchUrl);
            if (this.props.onClick) {
                this.props.onClick();
            }
        };

        const searchNav = () => {
            return (
                <Nav className="ml-auto">
                    <Form inline onSubmit={search}>

                    <FormGroup key="search-text" className="search-group" >
                        <Input type="text" name="searchString" id="searchString" value={searchString} onChange={inputUpdate('searchString')} placeholder="Etsi" />
                    </FormGroup>
                    <Button key="search-button" className="search-button" type="submit">
                        <FontAwesome className="menu-search" name='search' size="2x"/>
                    </Button>
                    </Form>                               
                </Nav>
            )
        }
        
        const searchElement = searchNav();
        return (
            <div key="search" className="search">
                {searchElement}
            </div>
        );
    }
}

SearchElement.propType = {
    onClick: PropTypes.func
};


export default withRouter(SearchElement);