"use strict";
import '../../style/pages.css';
import React from 'react';
import PropTypes from 'prop-types'
import PagesList from './pages_list';
import { log } from '../general';

class PagesPage extends React.Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        return (
            <PagesList {...this.props}/>
        );
    }    
}

PagesPage.propType = {
    pages: PropTypes.array.isRequired,
    page: PropTypes.number,
};

export default PagesPage;