"use strict";
import '../../style/articles.css';
import React from 'react';
import PropTypes from 'prop-types'
import { log, goTo } from '../general';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import ElementCard from './element_card';
import ArticleFooter from './article_footer';


class ArticleLine extends React.Component{
    constructor(props, context) {
        super(props, context);
    }

    render() {
        const xlValue = 4;
        const lgValue = 6;
        const mdValue = 6;
        const smValue = 6;
        const xsValue = 12;    
        const article_image =  this.props.article.indeximage != undefined ? "/images/interviewimages/" + this.props.article.indeximage : undefined;

        const header = (text) => {
            return (
                <p>
                    {text}
                </p>
            )

        };
        const article_id = "/articles/" + this.props.page + "/" + this.props.article.id;
        const paragraph = (ingres) => {
            return (
                <p>
                    {ingres}
                </p>
            )
        };
    
        return (
            <Col key={this.props.article.id} xl={xlValue} lg={lgValue} md={mdValue} sm={smValue} xs={xsValue}>
                {goTo(article_id,
                    <ElementCard keyName={this.props.article.id.toString()} performer={this.props.article.performer} image={article_image} link={article_id}
                        text={paragraph(this.props.article.ingres)} date={this.props.article.date} no_text={false} interview={true}/>                        
                )}
            </Col>            
        );
    }
}

ArticleLine.propType = {
    article: PropTypes.object.isRequired,
    page: PropTypes.number,
};


export default ArticleLine;
