"use strict";
import "../../style/search.css";
import "../../style/ad.css";
import React from 'react';
import PropTypes from 'prop-types';
import { filter } from 'ramda';
import { Nav, NavLink, NavItem, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';

import {log, isUndefinedOrEmpty} from '../general';
import Releases from "./releases";


class AllReleasesPage extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
          activeTab: '1'
        };        
    }

    componentDidMount() {
        const monthDate = new Date();
        const monthAsNumber = monthDate.getMonth() + 1;
        this.setState({activeTab: monthAsNumber.toString()});        
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
    }

    render() {
        const {fetched, releases} = this.props;
        if (!fetched) {
            return false;
        }

        var releasesTabElem = [];
        var releasesNavElem = [];
        var keyNumber = 1;        
        const monthDate = new Date();
        
        const releaseTab = (keyNumber, releases) => { 
            return(
                <TabPane tabId={keyNumber.toString()}>
                    <Releases releases={releases}/>
                </TabPane>
            )
        };
        const navLink = (keyNumber, title) => {
            return (
                <NavLink
                    className={classnames({ active: this.state.activeTab === keyNumber.toString() })}
                    onClick={() => { this.toggle(keyNumber.toString()); }}
                >
                    {title}
                </NavLink>
            );
        }

        if (!isUndefinedOrEmpty(releases)) {
            for (let month = 0; month < 12; month++) { 
                monthDate.setMonth(month);
                monthDate.setDate(1);
                const getMonthReleases = (release) => {
                    const releaseDate = new Date(release.date);

                    if (monthDate.getFullYear() == releaseDate.getFullYear() && monthDate.getMonth()  == releaseDate.getMonth() ) {
                        return true;
                    }
                    return false;
                }        
                const releaseMonth = filter(getMonthReleases, releases);
                const monthAsText = monthDate.toLocaleString('fi-fi', { month: 'long' });
                const title = <div className="tab-header">{monthAsText}</div>;
                releasesTabElem.push(releaseTab(keyNumber, releaseMonth)); 
                releasesNavElem.push(navLink(keyNumber, title));
                keyNumber = keyNumber + 1;   
            }
        }

        return (
            <div className="all-releases">
                <div>
                    <Nav tabs justified>
                        <NavItem>
                            {releasesNavElem[0]}
                        </NavItem>                            
                        <NavItem>
                            {releasesNavElem[1]}
                        </NavItem>                            
                        <NavItem>
                            {releasesNavElem[2]}
                        </NavItem>                            
                        <NavItem>
                            {releasesNavElem[3]}
                        </NavItem>                            
                        <NavItem>
                            {releasesNavElem[4]}
                        </NavItem>                            
                        <NavItem>
                            {releasesNavElem[5]}
                        </NavItem>                            
                        <NavItem>
                            {releasesNavElem[6]}
                        </NavItem>                            
                        <NavItem>
                            {releasesNavElem[7]}
                        </NavItem>                            
                        <NavItem>
                            {releasesNavElem[8]}
                        </NavItem>                            
                        <NavItem>
                            {releasesNavElem[9]}
                        </NavItem>                            
                        <NavItem>
                            {releasesNavElem[10]}
                        </NavItem>                            
                        <NavItem>
                            {releasesNavElem[11]}
                        </NavItem>                            
                    </Nav>
                </div>
                <TabContent activeTab={this.state.activeTab}>
                    {releasesTabElem}
                </TabContent>
            </div>
        );
    }   
}

AllReleasesPage.propTypes = {
    fetched: PropTypes.bool.isRequired,
    releases: PropTypes.array,
};


export default AllReleasesPage;