'use strict';
import React from 'react';
import PropTypes from 'prop-types'
import R,{ concat, slice } from 'ramda';
import { RotateLoader } from 'react-spinners';
import axios from 'axios';
import { queryApiUrl, httpRequest, log, sortFirstByMatch, sortFirstByLike } from '../general';
import SearchPage from '../components/search_page';

class Search extends React.Component {
    constructor() {
        super();
        this.state = { reviews: [], others: [], interviews:[], news: [], gigs: [], releases:[], demos:[], videos:[], pages:[], totalAmount: 0, fetched: false, error: false, tooShort: false };
    }
    
    shouldComponentUpdate(nextProps, nextState) {

        const searchString = nextProps.match.params.text;        
        if (searchString != this.props.match.params.text) {
            if (searchString.length > 2) {
                this.state.fetched = false;
                this.startSearch(nextProps.match.params.text);
            } else {
                this.setState({tooShort: true, fetched: true});                
            }
        }

        return true;
    }

    startSearch(text) {
        
        const searchReviews = (searchString) => {
            const urlPath = queryApiUrl('imp_albums/rows?sort=date&sort_order=DESC&limit=1000&columns=id,performer,record,coverart,date&filters[review][contains]=' + searchString + '&filters[performer][logical]=or&filters[performer][contains]=' + searchString + '&in[published]=1');
            return axios.get(urlPath);
        }

        const searchNews = (searchString) => {
            const urlPath = queryApiUrl('imp_news/rows?sort=date&sort_order=DESC&limit=1000&filters[news][contains]=' + searchString + '&filters[topic][logical]=or&filters[topic][contains]=' + searchString + '&in[published]=1');
            return axios.get(urlPath);
        }

        const searchOthers = (searchString) => {
            const urlPath = queryApiUrl('imp_booksmovies/rows?sort=date&sort_order=DESC&limit=1000&columns=id,title,director,coverart,date&filters[review][contains]=' + searchString + '&filters[director][logical]=or&filters[director][contains]=' + searchString + '&filters[title][logical]=or&filters[title][contains]=' + searchString + '&in[published]=1');
            return axios.get(urlPath);
        }

        const searchDemos = (searchString) => {
            const urlPath = queryApiUrl('imp_demos/rows?sort=date&sort_order=DESC&limit=1000&columns=id,performer,demoname,date&filters[review][contains]=' + searchString + '&filters[performer][logical]=or&filters[performer][contains]=' + searchString + '&in[published]=1');
            return axios.get(urlPath);
        }

        const searchInterviews = (searchString) => {
            const urlPath = queryApiUrl('imp_interviews/rows?sort=date&sort_order=DESC&limit=1000&columns=id,performer,ingres,indeximage,date&filters[interview][contains]=' + searchString + '&filters[performer][logical]=or&filters[performer][contains]=' + searchString + '&in[published]=1');
            return axios.get(urlPath);
        }

        const searchReleases = (searchString) => {
            const urlPath = queryApiUrl('imp_releases/rows?sort=date&sort_order=DESC&limit=1000&filters[performer][contains]=' + searchString + '&filters[record][logical]=or&filters[record][contains]=' + searchString + '&in[published]=1');
            return axios.get(urlPath);
        }

        const searchGigs = (searchString) => {
            const urlPath = queryApiUrl('imp_gigs/rows?sort=date&sort_order=DESC&limit=1000&filters[bands][contains]=' + searchString + '&in[published]=1');
            return axios.get(urlPath);
        }

        const searchVideos = (searchString) => {
            const urlPath = queryApiUrl('imp_videos/rows?sort=date&sort_order=DESC&limit=1000&filters[title][contains]=' + searchString + '&in[published]=1');
            return axios.get(urlPath);
        }

        const searchPages = (searchString) => {
            const urlPath = queryApiUrl('imp_pages/rows?sort=date&sort_order=DESC&limit=1000&filters[title][contains]=' + searchString + '&filters[page_html][logical]=or&filters[page_html][contains]=' + searchString + '&in[published]=1');
            return axios.get(urlPath);
        }
        
        const self = this;
        
        axios.all([
            searchReviews(text), 
            searchNews(text),
            searchOthers(text),
            searchDemos(text),
            searchInterviews(text),
            searchReleases(text),
            searchGigs(text),
            searchVideos(text),
            searchPages(text),
            ])
            .then(axios.spread(function (resultReviews, resultNews, resultOthers, resultDemos, resultInterviews, resultReleases, resultGigs, resultVideos, resultPages) {
                window.scrollTo(0, 0);

                // All the requests are now complete
                self.setState({
                    fetched: true, 
                    reviews: resultReviews.data.data,
                    news: resultNews.data.data,
                    others: resultOthers.data.data,
                    demos: resultDemos.data.data,
                    interviews: resultInterviews.data.data,
                    releases: resultReleases.data.data,
                    gigs: resultGigs.data.data,
                    videos: resultVideos.data.data,
                    pages: resultPages.data.data,
                });
            })
        );            
    }

    componentWillMount() {
        const searchString = this.props.match.params.text;
        if (searchString.length > 2) {
            this.startSearch(this.props.match.params.text);
        } else {
            this.setState({tooShort: true, fetched: true});
        }
        window.scrollTo(0, 0);
    }    
     
    render() {
        const searchString  = this.props.match.params.text;
        
        if (this.state.fetched == false) {
            return (
                <div className="search-page-fixed searching">
                    <RotateLoader color="#4C0001" loading={!this.state.fetched}/>
                </div>
            );
        } 
        

        if (this.state.tooShort == true || searchString.length < 3) {
            return (
                <div className="search-page">
                    Käytä vähintään kolmea kirjainta.
                </div>
            );
        }
        const reviewsSortedList = sortFirstByMatch(searchString, 'performer', this.state.reviews);
        const demosSortedList = sortFirstByMatch(searchString, 'performer', this.state.demos);
        const articlesSortedList = sortFirstByLike(searchString, 'performer', this.state.interviews);
        const otherReviewsSortedList = sortFirstByMatch(searchString, 'director', this.state.others);
         
        return (
            <div className="search-page">
                <SearchPage 
                    fetched = {this.state.fetched}
                    reviews = {reviewsSortedList}
                    others = {otherReviewsSortedList}
                    interviews = {articlesSortedList}
                    news = {this.state.news}
                    demos = {demosSortedList}
                    gigs = {this.state.gigs}
                    videos = {this.state.videos}
                    releases = {this.state.releases}
                    pages = {this.state.pages}
                    />
            </div>
        );
    }
}

Search.propTypes = {
    windowWidth: PropTypes.number,
    windowHeight: PropTypes.number,
};

export default Search;
