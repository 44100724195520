"use strict";
import '../../style/demos.css';
import React from 'react';
import PropTypes from 'prop-types'
import { Card, Button, CardHeader, CardFooter, CardBody,
    CardTitle, CardText, Media } from 'reactstrap';
import { Link } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import { log, createMarkup, createDangerous, authorLink, bandLink, serverAddress, dateDisplayer, dateConverted, scoreDisplayer, paragraphOrMaxLength, paragraphRemoveImagesAndVideos } from '../general';

class DemosDetailPage extends React.Component{
    constructor(props, context) {
        super(props, context);
    }

    render() {
        var demos = undefined;

        if (this.props.demos) {
            demos = this.props.demos.map((demo, index) => {
                    const demo_id = "/demos/" + this.props.page + "/" + demo.id;

                    const headerPanel = (performer, demoname) => {
                        return (
                            <Link to={ demo_id }>
                                <div className="demo-header-list">
                                    {performer} &ndash; {demoname}
                                </div>   
                            </Link>                            
                        )
                    };
                    
                    const max_length_text = 900;
                    const text = paragraphRemoveImagesAndVideos(demo.review);
                    const text_shorten = paragraphOrMaxLength(text, max_length_text);
                    const paragraphMarkup = createMarkup(text_shorten);
                    
                    const element = createDangerous(paragraphMarkup);

                    return (
                            <div key={index} className="demo-body">
                                <Card>
                                    <CardHeader>
                                        {headerPanel(demo.performer, demo.demoname)}
                                    </CardHeader>
                                    <CardBody>
                                        <div key={index} className="demo-review">
                                            {element}
                                            <Link className="demo-list-read-more" to={ demo_id }>
                                                Lue lisää
                                            </Link>                                                
                                        </div>
                                    </CardBody>
                                </Card> 
                            </div>
                    );

                }
            );
        }
    
        return (
            <div>
                {demos}
            </div>
        );        
    }
}

DemosDetailPage.propType = {
    demos: PropTypes.array.isRequired,
    location: PropTypes.object,
    page: PropTypes.number,
};


export default DemosDetailPage;
