"use strict";
import "../../style/today.css";
import React from 'react';
import PropTypes from 'prop-types'
import FontAwesome from 'react-fontawesome';
import { find, propEq, findIndex,isEmpty} from 'ramda';
import { Collapse, Card, CardBody, CardHeader, CardGroup, Button } from "reactstrap";
import { TwitterButton } from 'react-social';
import { log, facebookLink, goTo, linkTo, dateConverted, todayCalender } from '../general';
import moment from 'moment';
import 'moment/locale/fi';


class Gigs extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {gigs: undefined};
    }


    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.gigs.length > 0) {
            return true;
        }
        return false;
    }

    componentWillMount() {
        if (this.props.gigs.length > 0) {
            this.setState({gigs: this.props.gigs})
        }    
    }

    toggle(id) {
        const element = find(propEq('id', id))(this.state.gigs); 
        const elementId = findIndex(propEq('id', id))(this.state.gigs); 
        const collapse = !element.collapse;
        const gigs = this.state.gigs;
        gigs[elementId].collapse = collapse;
    
        // update state
        this.setState({
            gigs,
        });        
        
    } 

    render() {
        const {bandLength} = this.props;
        const {gigs} = this.state;

        if (gigs == undefined || gigs.length <= 0) {
            return false;
        }

        const bandsToList = bands => {
            const band_list = bands.split("/");
            const new_band_list = band_list.map(band => band.trim());
            return new_band_list;
        };

        const short_band_list = bands => {            
            var bands_shorten = '';
            for (var band of bandsToList(bands)) {
                if (band.length > bandLength && bands_shorten.length == 0) {
                    bands_shorten = band;
                    break;
                }
                if (bands_shorten.length + band.length > bandLength) {
                    bands_shorten = bands_shorten + " + muita";
                    break;
                }
                if (bands_shorten.length != 0) {
                    bands_shorten = bands_shorten + ", ";

                }
                bands_shorten = bands_shorten + band;
            }
    
            return bands_shorten;            
        };

        const header = gig => { 
            const gigDay = todayCalender(gig);
            const bands = short_band_list(gig.bands);
            return (
                <div className="gig-header">
                    {gigDay}
                    <div className="icon-right">
                        <div className="gig-header-text">
                            <span className="gig-place">{gig.venue}</span>
                            <span className="gig-bands">{bands}</span>                    
                        </div>
                    </div>
                    <div className="icon-down-arrow">
                    <FontAwesome name="angle-down" className="down-arrow"/>
                    </div>
                </div>
            ); 
        };

        const mappedGigs = this.state.gigs.map(gig => {
            const headerText = header(gig);
            const new_band_list = bandsToList(gig.bands); 
            const bands = new_band_list.map(band => { 
                return (<li key={band}>{band}</li>);
            });

            const fbevent = (link) => {
                if (isEmpty(link) || link.length < 2)  {
                    return undefined;
                }
                return (linkTo(link,
                    <span>
                        <FontAwesome className="fbevent" name='facebook-square' size="2x"/>
                        <div className="fbevent-text">-tapahtuma</div>
                    </span>, "fbevent-icon"
                    )
                );
            }


            const twitUrl = (gig) => {
                const gigDate = moment(gig.date).format('LL'); 
                const message = "Keikalla " + gigDate + " : " + gig.venue.toUpperCase() + " : " + gig.bands.toUpperCase();
                return (
                    <TwitterButton title="Tweet" message={message} url="" element="a" className="">
                        <span className="gig-twitter tw">
                            Jaa: <i><FontAwesome name="twitter" className="share"/></i>
                        </span>
                    </TwitterButton>                
                );                                     
            }
            return [
                <div>
                    <Button color="secondary" onClick={() => this.toggle(gig.id)} style={{ marginBottom: '0.5rem', width: '100%', 'textAlign': 'left' }}>{headerText}</Button>

                    <Collapse isOpen={gig.collapse}>
                    <Card>
                        <CardBody>
                            <ul className="gig-band">
                                {bands}
                            </ul> 

                            <div>
                                {gig.addinfo} 
                            </div>
                            <div className="gig-event">
                            {fbevent(gig.link)}
                            {twitUrl(gig)}
                            </div>
                        </CardBody>
                    </Card>
                    </Collapse>
                    
                </div>
            ];
        });

        return (
            <div className="gigs">
                {mappedGigs}
            </div>
        );
    }       
}

Gigs.propTypes = {
    gigs: PropTypes.array.isRequired,
    bandLength: PropTypes.number.isRequired,
};


export default Gigs;