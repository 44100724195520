'use strict';
import '../../style/articles.css';
import React from 'react';
import FontAwesome from 'react-fontawesome';
import { authorLink, photographerLink } from '../general';

const ArticleFooter = props => {

    const author = props.author;    
    const photos = props.photographer;
    return (
        <div className="article-footer">
            <FontAwesome className="date-icon" name='calendar-o'/>{props.date}
            <FontAwesome className="writer-icon" name='pencil-square-o'/>{author}
            <FontAwesome className="camera-icon" name='camera'/>{photos}
         </div>
    );
}

export default ArticleFooter;