'use strict';
import "../../style/headpage.css";
import React from 'react';
import PropTypes from 'prop-types'
import HeadPage from '../components/headpage';
import { queryApiUrl, httpRequest, log } from '../general';

class Head extends React.Component {
    constructor() {
        super();
    }
    
    render() {
        if (this.context.layoutState.error == true) {
            return (
                <div>
                    Tietokantaongelma!
                </div>
            )
        }

        const windowWidth = this.context.layoutState.todayToBottom < this.props.windowWidth ? this.props.windowWidth - 300 : this.props.windowWidth - 15;
        
        return (
            <div className="head-page">
                <HeadPage windowWidth={windowWidth} windowHeight={this.props.windowHeight}  news={this.context.layoutState.news} reviews={this.context.layoutState.reviews} interviews={this.context.layoutState.interviews}/>
            </div>
        );
    }
}

Head.contextTypes = {
    layoutState: PropTypes.object
};

Head.propTypes = {
    windowWidth: PropTypes.number,
    windowHeight: PropTypes.number,
};

export default Head;
