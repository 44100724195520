'use strict';
import "../../style/articles.css";
import React  from 'react';
import PropTypes from 'prop-types';
import { RotateLoader } from 'react-spinners';
import axios from 'axios';
import {withRouter} from 'react-router-dom';
import DemoReviewDetailPage from '../components/demos_detail_page';
import PaginationComponent from '../components/pagination'
import { httpRequest, queryApiUrl, log } from '../general';

class DemoReviewDetail extends React.Component {
    constructor() {
        super();
        this.handleSelect = this.handleSelect.bind(this);                
        this.state = { demo_reviews: [], totalAmount: 0, currentPage: 1, numberOfDemos: 10, fetched: false, error: false };
    }

    handleSelect(eventKey) {
        const page = parseInt(eventKey.selected);
        const link = "/demos/" + (page + 1);
        this.props.history.push(link);
        this.setState({fetched: false});
    }    

    
    getDemoReviewFromApiAsync(state, props) {
        const currentPage = props.match.params.page;
        const currentPageAsNumber = parseInt(currentPage);

        const offset = (currentPageAsNumber - 1) * state.numberOfDemos;
        var left;
        if (state.totalAmount == 0)
            left = state.numberOfDemos;
        else
            left = state.totalAmount - offset;          
        const limit = left >= state.numberOfDemos ? state.numberOfDemos : left;
        const urlPath = queryApiUrl('imp_demos/rows?sort=date&sort_order=DESC&limit=' + limit + '&offset=' + offset + '&in[published]=1');

        const self = this;
        // Make a request for a user with a given ID
        axios.get(urlPath)
        .then(function (response) {
            window.scrollTo(0, 0);            
            self.setState({
                demo_reviews: response.data.data,
                totalAmount: response.data.meta.total_entries, 
                fetched: true,
                currentPage: currentPage,
             });
         })
        .catch(function (error) {
            log("Error fetching demos");
            log(error);
            self.setState({demo_reviews:[], fetched: true, error: true});
        });
    }

    componentWillMount() {
        this.getDemoReviewFromApiAsync(this.state, this.props);
        window.scrollTo(0, 0);
    }

    shouldComponentUpdate(nextProps, nextState) {
        const currentPage = nextProps.match.params.page;
        if ( currentPage != this.props.match.params.page ) {
            this.getDemoReviewFromApiAsync(nextState, nextProps);
            return false; 
        }
        if (nextState.fetched == true && this.state.demo_reviews.length > 0) {
 
            return true;
        } else {
            this.getDemoReviewFromApiAsync(nextState, nextProps);
            return false;
        }
    }
    

    render() {
        const currentPage = this.props.match.params.page;
        const currentPageAsNumber = parseInt(currentPage) -1;      

        if (this.state.fetched == false) {
            return (
                <div className="demo-page-fixed searching">
                    <RotateLoader color="#4C0001" loading={!this.state.fetched}/>
                </div>            
            )
        }
        return (
            
            <div className="demo-page">
                <DemoReviewDetailPage demos={this.state.demo_reviews} location={this.props.match} page={currentPage}/>
                <PaginationComponent  
                    totalAmount={this.state.totalAmount}
                    numberOfElementsPerPage={this.state.numberOfDemos}
                    currentPage={currentPageAsNumber}
                    handleSelect={this.handleSelect} />
            </div>
        );
    }
}

DemoReviewDetail.propTypes = {
    match: PropTypes.object
};

export default withRouter(DemoReviewDetail);
