"use strict";
import "../../style/pagination.css";
import React from 'react';
import PropTypes from 'prop-types';
import Center from 'react-center';
import ReactPaginate from 'react-paginate';
import Dimensions from 'react-dimensions';
import { log } from '../general';

class PaginationComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        var pageRange = 5;
        var marginPage = 2;
        // log("pagination");
        // log(this.props);
        if (this.props.containerWidth < 800) {
            pageRange = 4;
        }
        if (this.props.containerWidth < 700) {
            pageRange = 3;
        }
        if (this.props.containerWidth < 600) {
            pageRange = 2;
        }
        if (this.props.containerWidth < 500) {
            pageRange = 2;
            marginPage = 1;
        }
        if (this.props.containerWidth < 500) {
            pageRange = 1;
            marginPage = 1;
        }

        const numberOfPages = ~~(this.props.totalAmount / this.props.numberOfElementsPerPage) + 1;
        return (
            <div>
            <Center>
                <ReactPaginate 
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={<a href="">...</a>}
                    breakClassName={"break-me"}
                    pageCount={numberOfPages}
                    marginPagesDisplayed={marginPage}
                    pageRangeDisplayed={pageRange}
                    onPageChange={this.props.handleSelect}
                    containerClassName={"my-pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    initialPage={this.props.currentPage} />
            </Center>
            </div>
        );
    }       
}

PaginationComponent.propTypes = {
    totalAmount: PropTypes.number.isRequired,
    numberOfElementsPerPage: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    handleSelect: PropTypes.func.isRequired,
};


export default PaginationComponent;