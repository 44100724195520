"use strict";
import '../../style/other_review.css';
import React from 'react';
import PropTypes from 'prop-types';
import OtherReviewLine from './other_review_line';
import { log } from '../general';
import { Container, Row} from 'reactstrap';

class OtherReviewsList extends React.Component{
    render() {
        var otherReviews;
        if (this.props.otherReviews) {
            otherReviews = this.props.otherReviews.map(item =>
                <OtherReviewLine key={item.id} review={item} page={this.props.page}/>
            );
        }
        return (
            <div>
                <Container>
                    <Row className="show-grid">
                        {otherReviews}
                    </Row>
                </Container>            

            </div>     
        );
    }
}

OtherReviewsList.propType = {
    otherReviews: PropTypes.array.isRequired,
    page: PropTypes.number,
};


export default OtherReviewsList;