"use strict";
import '../../style/other_review.css';
import React from 'react';
import PropTypes from 'prop-types'
import OtherReviewsList from './other_review_list';
import { log } from '../general';

class OtherReviewsPage extends React.Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        return (
            <OtherReviewsList otherReviews={this.props.otherReviews} page={this.props.page}/>
        );
    }    
}

OtherReviewsPage.propType = {
    otherReviews: PropTypes.array.isRequired,
    page: PropTypes.number,
};

export default OtherReviewsPage;