import React, { PropTypes, Component } from 'react';
import { log } from '../general';

class ImproveAds extends Component {
    componentDidMount() {
        const script = document.createElement("script");
        const protocol = ('https:' == document.location.protocol ? 'https' : 'http');
        const uri = protocol + '://adsby.improveads.fi/imp?z=6413&rnd=' + new String (Math.random()).substring (2, 11);
        script.type = 'text/javascript';
        script.src = uri;
        script.async = false;
        script.innerHTML = "document.write";
        this.instance.appendChild(script);

    }

    render() {
        return <div className="improveads" ref={el => (this.instance = el)} />;
    }        
}

ImproveAds.propTypes = {
  client: PropTypes.string.isRequired,
  slot: PropTypes.string.isRequired,
  format: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
};

ImproveAds.defaultProps = {
  style: { display: 'block' },
  format: 'auto',
};

export default ImproveAds;
