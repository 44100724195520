"use strict";
import '../../style/videos.css';
import React from 'react';
import PropTypes from 'prop-types';
import VideosLine from './videos_line';
import { log } from '../general';
import { Container, Row } from 'reactstrap';

class VideosList extends React.Component{
    render() {
        var videos;
        if (this.props.videos) {
            videos = this.props.videos.map(video => { 
                return (
                        <VideosLine key={video.id} video={video} playVideo={this.props.playVideo} videoId={this.props.videoId} page={this.props.page}/>
                    );
                }
            );
        }
        return (
            <div>
                <Container>
                    <Row className="show-grid">
                        {videos}
                    </Row>
                </Container>              
            </div>     
        );
    }
}

VideosList.propType = {
    videos: PropTypes.array.isRequired,
    videoId: PropTypes.string.isRequired,
    playVideo: PropTypes.array.isRequired,
    page: PropTypes.number,
};


export default VideosList;