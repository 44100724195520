"use strict";
import '../../style/pages.css';
import React from 'react';
import PropTypes from 'prop-types'
import PageLine from './page_line';
import { log } from '../general';
import { Container,  Row } from 'reactstrap';

class PagesList extends React.Component{
    render() {
        var pages;
        if (this.props.pages) {
            pages = this.props.pages.map(page =>
                <PageLine key={page.id} page={page} sivu={this.props.page}/>
            );
        }
        return (
            <Container>
                <Row className="show-grid">
                    {pages}
                </Row>
            </Container>                        
        );
    }
}

PagesList.propType = {
    pages: PropTypes.array.isRequired,
    page: PropTypes.number,
};


export default PagesList;