"use strict";
import '../../style/articles.css';
import React from 'react';
import PropTypes from 'prop-types'
import ArticleLine from './article_line';
import { log } from '../general';
import { Container, Row, Col } from 'reactstrap';

class ArticlesList extends React.Component{
    render() {
        var articles;
        if (this.props.articles) {
            articles = this.props.articles.map(article =>
                <ArticleLine key={article.id.toString()} article={article} page={this.props.page}/>
            );
        }
        return (
            <Container>
                <Row className="show-grid">
                    {articles}
                </Row>
            </Container>                        
        );
    }
}

ArticlesList.propType = {
    articles: PropTypes.array.isRequired,
    page: PropTypes.number,
};


export default ArticlesList;