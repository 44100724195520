"use strict";
import '../../style/radios.css';
import React from 'react';
import PropTypes from 'prop-types'
import RadioList from './radio_list';
import ElementCard from './element_card';
import { log } from '../general';

class RadioPage extends React.Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        const image_name = "images/DTP-header.jpg";
        return (
            <div className="radios-page">
                <ElementCard key={1} performer="" image={image_name} no_text={false} promote={1} news={true}/>
                <RadioList radios={this.props.radios}/>
            </div>
        );
    }    
}

RadioPage.propType = {
    radios: PropTypes.array.isRequired,
};

export default RadioPage;