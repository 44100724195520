
"use strict";
import '../../style/search.css';
import React from 'react';
import PropTypes from 'prop-types';
import ReactImageFallback from 'react-image-fallback';
import { slice } from 'ramda';
import { Media } from 'reactstrap';
import he from 'he';

import { log, goTo, dateConverted, dateDisplayer } from '../general';
import PaginationComponent from './pagination';

class SearchResultOthers extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            currentPage: 1,
            totalAmount: props.results.length,
            numberOfItems: 30,
        }
        this.handleSelect = this.handleSelect.bind(this);

    }

    handleSelect(eventKey) {
        const page = parseInt(eventKey.selected);
        window.scrollTo(0,0);
        const items = slice(page * this.state.numberOfItems, page * this.state.numberOfItems + this.state.numberOfItems, this.props.results);
        this.setState({currentPage: page, items: items});
    }

    componentWillReceiveProps(nextProps) {
        var items = [];
        if (nextProps.results != undefined && nextProps.results.length > 0) {
            items = slice(0, this.state.numberOfItems, nextProps.results);
        }
        this.setState({items: items});
    }

    render() {
        
        if (this.state.items == undefined)
            return false;

        const currentPageAsNumber = parseInt(this.state.currentPage) -1;
        
        const results = this.state.items.map((other, index) => {
            const other_image =  other.coverart != undefined ? "/images/bookcovers/" + other.coverart : undefined;
            const other_id = "/otherreviews/1/" + other.id;
            const dateText = dateDisplayer(dateConverted(other.date));
            
            return (
                <div key={index} className="search-result-line search-review-others">
                    {goTo(other_id,
                        <Media>
                            <Media left className="search-other-left">
                                <ReactImageFallback
                                src={other_image}
                                fallbackImage="/images/default_review.png"
                                className="search-other-image" />
                            </Media>                                
                            <Media body>
                                <Media heading  className="media-header">
                                    {he.decode(other.title)} 
                                </Media>
                                {he.decode(other.director)}
                                {dateText}
                            </Media>
                        </Media>                                        
                    )}
                    <hr/>
                </div>
            );
        });
    
        return (
            <div className="search-results">
                {results}
                <div className="search-pagination">
                    <PaginationComponent  
                        totalAmount={this.state.totalAmount}
                        numberOfElementsPerPage={this.state.numberOfItems}
                        currentPage={currentPageAsNumber}
                        handleSelect={this.handleSelect} />
                </div>
            </div>
        );
    }
}

SearchResultOthers.propType = {
    results: PropTypes.array.isRequired
};


export default SearchResultOthers;