"use strict";
import '../../style/playlists.css';
import React from 'react';
import PropTypes from 'prop-types'
import ReactImageFallback from 'react-image-fallback';
import { log, goTo, periodOrMaxLength, createMarkup, createDangerous } from '../general';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import ElementCard from './element_card';


class PlaylistLine extends React.Component{
    constructor(props, context) {
        super(props, context);
    }

    render() {
        const xlValue = 4;
        const lgValue = 6;
        const mdValue = 6;
        const smValue = 6;
        const xsValue = 12;    
        const playlistImage =  this.props.playlist.playlistimage != undefined ? "/images/" + this.props.playlist.playlistimage : undefined;

        const playlistId = "/playlists/" + this.props.page + "/" + this.props.playlist.id;
        const text_shorted = periodOrMaxLength(this.props.playlist.ingress, 300);
        const paragraphMarkup = createMarkup(text_shorted);
        const element = createDangerous(paragraphMarkup);
    
        return (
            <Col key={this.props.playlist.id} xl={xlValue} lg={lgValue} md={mdValue} sm={smValue} xs={xsValue}>
                {goTo(playlistId,
                    <ElementCard keyName={this.props.playlist.id.toString()} performer={this.props.playlist.headline} image={playlistImage} link={playlistId}
                        text={element} date={undefined} no_text={false} interview={true}/>                        
                )}
            </Col>            
        );
    }
}

PlaylistLine.propType = {
    playlist: PropTypes.object.isRequired,
    page: PropTypes.number,    
};


export default PlaylistLine;
 