'use strict';
import React from 'react';
import PropTypes from 'prop-types'
import R,{ concat, slice } from 'ramda';
import { RotateLoader } from 'react-spinners';
import axios from 'axios';
import { queryApiUrl, getStartThisYear, httpRequest, log, sortFirstByMatch, sortFirstByLike } from '../general';
import AllReleasesPage from '../components/all_releases_page';

class AllReleases extends React.Component {
    constructor() {
        super();
        this.state = {  releases: [], totalAmount: 0, fetched: false, error: false };
    }

    componentWillMount() {
        const year = getStartThisYear();
        this.state.fetched = false;
        this.startSearch(year);
        window.scrollTo(0, 0);
    }    

    startSearch(year) {
        
        const urlPath = queryApiUrl('imp_releases/rows?sort=date&sort_order=ASC&limit=4000&filters[date][gte]=' + year +  '&in[published]=1');

        const self = this;
        // Make a request for a user with a given ID
        axios.get(urlPath)
        .then(function (response) {
            self.setState({
                releases: response.data.data,
                totalAmount: response.data.meta.total_entries, 
                fetched: true,
             });
         })
        .catch(function (error) {
            self.setState({releases:undefined, fetched: true, error: true});
        });    
    }

     
    render() {
        const { fetched, releases} = this.state;
        if (fetched == false) {
            return (
                <div className="all-releases-fixed searching">
                    <RotateLoader color="#4C0001" loading={!fetched}/>
                </div>
            );
        } 
        
         
        return (
            <div className="all-releases-page">
                <AllReleasesPage 
                    fetched = {fetched}
                    releases = {releases}
                    />
            </div>
        );
    }
}

AllReleases.propTypes = {
    windowWidth: PropTypes.number,
    windowHeight: PropTypes.number,
};

export default AllReleases;
