"use strict";
import "../../style/share.css";
import React from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import classnames from 'classnames';
import { FacebookButton, TwitterButton } from 'react-social';
import {log, bandLink, facebookLink, facebook, spotify, deezer, cdon, levykauppax, rockshirts } from '../general';

class ShareButtons extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.toggleSpotify = this.toggleSpotify.bind(this);
        this.toggleDeezer = this.toggleDeezer.bind(this);
        this.state = {
            spotifyTooltipOpen: false,
            deezerTooltipOpen: false
        };
    }


    toggleSpotify() {
        this.setState({
        spotifyTooltipOpen: !this.state.spotifyTooltipOpen
        });
    }

    toggleDeezer() {
        this.setState({
        deezerTooltipOpen: !this.state.deezerTooltipOpen
        });
    }

    handleClick(e) {
        FB.ui({
            method: 'share',
            href: this.props.currentUrl,
        }, function(response){});    }

    render() {

        const spotifyText = spotify(this.props.spotifyLink, this.state.spotifyTooltipOpen, this.toggleSpotify);
        const deezerText = deezer(this.props.deezerLink, this.state.deezerTooltipOpen, this.toggleDeezer);
        const cdonText = this.props.cdonLink ? cdon(this.props.performer, true) : undefined;
        const xText = this.props.xLink ? levykauppax(this.props.performer) : undefined; 
        const rockShirtsText = this.props.rockShirtsLink ? rockshirts(this.props.rockShirtsLink) : undefined;
        const buyText = this.props.buyLink ? cdon(this.props.buyLink, false) : undefined;
        const bandlinkText = this.props.bandLink ? bandLink(this.props.bandLink) : undefined;
        const facebookHomeText = this.props.facebookHomeLink  ? facebookLink(this.props.facebookHomeLink) : undefined;

        var kauppaLinkit = undefined;
        if (cdonText || xText || rockShirtsText || buyText) {
            kauppaLinkit = <div className="share-heading">Osta</div>;
        } 
        var soittoLinkit = undefined;
        if (spotifyText || deezerText) {
            soittoLinkit = <div className="share-heading">Soita</div>;
        }
        var kotiLinkit = undefined;
        if (bandlinkText || facebookHomeText) {
            kotiLinkit = <div className="share-heading">Seuraa</div>;
        }
        // log(this.props);
        const shareButtons =  classnames('share-buttons', {horizontal: this.props.width < 920});

        /*
        <div>
        {xText}
        </div>
        */
        return (
            <div className={shareButtons}>
                <div className="share-heading">Jaa</div>
                <a href="#" onClick={this.handleClick}>
                    <div className="icon social fb">
                        <i><FontAwesome name="facebook-square" size="4x"/></i>
                    </div>
                </a>
                <TwitterButton title="Tweet" message={this.props.message} url={this.props.currentUrl} element="a" className="">
                    <div className="icon social tw">
                        <i><FontAwesome name="twitter-square" size="4x"/></i>
                    </div>
                </TwitterButton>
                {soittoLinkit}
                <div className="icon social spotify">
                    {spotifyText}
                </div>
                    {deezerText}
                <div>
                    {kauppaLinkit}
                </div>
                <div>
                    {cdonText}
                </div>
                <div>                
                    {rockShirtsText}                
                </div>
                <div>
                    {buyText}
                </div>
                <div>
                    {kotiLinkit}
                </div>
                <div className="icon social home">
                    {bandlinkText}
                </div>
                <div className="icon social facehome">
                    {facebookHomeText}                
                </div>
            </div>
        );
    }   
}


ShareButtons.propTypes = {    
    width: PropTypes.number.isRequired,
    performer: PropTypes.string.isRequired,
    message: PropTypes.string,
    currentUrl: PropTypes.string,
    bandLink: PropTypes.string,
    facebookLink: PropTypes.string,
    spotifyLink: PropTypes.string,
    deezerLink: PropTypes.string,
    cdonLink: PropTypes.string,
    buyLink: PropTypes.string,
    xLink: PropTypes.string,
    rockShirtsLink: PropTypes.string,    
    bandLink: PropTypes.string,
    facebookHomeLink: PropTypes.string,

};


export default ShareButtons;