"use strict";
import '../../style/review.css';
import React from 'react';
import PropTypes from 'prop-types'
import ReviewsList from './reviews_list';
import { log } from '../general';

class ReviewsPage extends React.Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        return (
            <ReviewsList reviews={this.props.reviews} page={this.props.page}/>
        );
    }    
}

ReviewsPage.propType = {
    reviews: PropTypes.array.isRequired,
    page: PropTypes.number,
};

export default ReviewsPage;