'use strict';
import "../../style/videos.css";
import React  from 'react';
import PropTypes from 'prop-types';
import { RotateLoader } from 'react-spinners';
import axios from 'axios';

import {withRouter} from 'react-router-dom';
import Lightbox from 'lightbox-react';
import {sortWith, descend, prop} from 'ramda';
import VideosPage from '../components/videos_page';
import Video from '../components/video';

import PaginationComponent from '../components/pagination'
import { httpRequest, queryApiUrl, log } from '../general';

class ImpTV extends React.Component {
    constructor() {
        super();
        this.handleSelect = this.handleSelect.bind(this);                
        
        this.state = { 
            videos: [], 
            totalAmount: 0, 
            currentPage: 1, 
            numberOfVideos: 18, 
            fetched: false,
            error: false,
            photoIndex: 0,
            isOpen: false                         
        };
    }

    handleSelect(eventKey) {
        const page = parseInt(eventKey.selected);
        const link = "/imptv/" + (page + 1);
        this.props.history.push(link);
        this.setState({fetched: false});
    }  

    playVideo = (id) => {
        
        this.state.videos.map((video, index) => {
            if (video.id == id) {
                this.setState({photoIndex:index, isOpen: true});
                return;
            }
        });
    }
    
    getVideosFromApiAsync(state, props) {
        const currentPage = props.match.params.page;
        const currentPageAsNumber = parseInt(currentPage);
        
        const offset = (currentPageAsNumber - 1) * state.numberOfVideos;
        var left;
        if (state.totalAmount == 0)
            left = state.numberOfVideos;
        else
            left = state.totalAmount - offset;  
        const limit = left >= state.numberOfVideos ? state.numberOfVideos : left;
        const urlPath = queryApiUrl('imp_impTV/rows?sort=date&sort_order=DESC&limit=' + limit + '&offset=' + offset + '&in[published]=1');

        const self = this;
        // Make a request for a user with a given ID
        axios.get(urlPath)
        .then(function (response) {

            const promoteSort = sortWith([
                descend(prop('promote')),
                descend(prop('date'))
              ]);
            const videoList = promoteSort(response.data.data);            
            self.setState({
                videos: videoList,
                totalAmount: response.data.meta.total_entries, 
                fetched: true,
                currentPage: currentPage,
             });
         })
        .catch(function (error) {
            log("Error fetching imptv");
            log(error);
            self.setState({videos:[], fetched: true, error: true});
        });

    }

    componentWillMount() {
        this.getVideosFromApiAsync(this.state, this.props);
    }

    shouldComponentUpdate(nextProps, nextState) {
        const currentPage = nextProps.match.params.page;
        if ( currentPage != this.props.match.params.page ) {
            this.getVideosFromApiAsync(nextState, nextProps);
            return false; 
        }
        if (nextState.fetched == true && this.state.videos.length > 0) {
            window.scrollTo(0, 0)
 
            return true;
        } else {
            if (nextState.fetched == true && nextState.error == true)
                return true;

            this.getVideosFromApiAsync(nextState, nextProps);
            return false;
        }
    }
    

    render() {
        const {
            photoIndex,
            isOpen,
        } = this.state;
        
        const currentPage = this.props.match.params.page;
        const currentPageAsNumber = parseInt(currentPage) -1;      

        var videoNames = [];

        if (this.state.fetched == false) {
            return (
                <div className="videos-page-fixed searching">
                    <RotateLoader color="#4C0001" loading={!this.state.fetched}/>
                </div>            
            )
        }
        if (this.state.error == true) {
            return (
                <div className="videos-page">
                    <h3>Tietokanta rikki...</h3>
                </div>
            )
        }
        const playerVideos = this.state.videos.map((video) => {
            videoNames.push(video.title);
            return (
                <Video video={video}/>
            );
        });
        const video_id = "imptv";

        return (
            
            <div className="videos-page">
                <VideosPage videos={this.state.videos} location={this.props.match} playVideo={this.playVideo} videoId={video_id} page={currentPage}/>
                <PaginationComponent  
                    totalAmount={this.state.totalAmount}
                    numberOfElementsPerPage={this.state.numberOfVideos}
                    currentPage={currentPageAsNumber}
                    handleSelect={this.handleSelect} />
                {isOpen &&
                    <Lightbox className="lightbox"
                        enableZoom={false}
                        clickOutsideToClose={true}

                        imageTitle={videoNames[photoIndex]}
                        mainSrc={playerVideos[photoIndex]}
                        nextSrc={playerVideos[(photoIndex + 1) % playerVideos.length]}
                        prevSrc={playerVideos[(photoIndex + playerVideos.length - 1) % playerVideos.length]}

                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() => this.setState({
                            photoIndex: (photoIndex + playerVideos.length - 1) % playerVideos.length,
                        })}
                        onMoveNextRequest={() => this.setState({
                            photoIndex: (photoIndex + 1) % playerVideos.length,
                        })}
                    />
                }
                    
            </div>
        );
    }
}

ImpTV.propTypes = {
    match: PropTypes.object
};

export default withRouter(ImpTV);
