"use strict";
import React from 'react';
import PropTypes from 'prop-types'
import Profile from './profile';
import { log } from '../general';
import { Container, Row } from 'reactstrap';

class ProfilesDetailPage extends React.Component{
    render() {
        var profiles;
        if (this.props.profiles) {
            profiles = this.props.profiles.map(profile =>
                <Profile key={profile.id} profile={profile}/>
            );
        }
        return (
            <Container>
                <Row className="show-grid">
                    {profiles}
                </Row>
            </Container>                        
        );
    }
}


ProfilesDetailPage.propType = {
    profiles: PropTypes.array.isRequired
};


export default ProfilesDetailPage;