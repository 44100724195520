'use strict';
import "../../style/today.css";
import React  from 'react';
import PropTypes from 'prop-types'
import {merge, map} from 'ramda';
import {Collapse, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import axios from 'axios';
import { queryApiUrl, httpRequest, getTodayAsString, log } from '../general';
import TodayElement from '../components/today_element';
import SearchElement from '../components/search_element';
import SomeElement from "../components/some_element";
import AdPlacer from '../components/ad_placer';

class Today extends React.Component {
    constructor() {
        super();
        this.toggle = this.toggle.bind(this);
        this.state = { popoverOpen: false, gigs: [], releases: [], gigsFetched: false, releasesFetched: false, error: false };
    }

    toggle() {
        this.setState({
            popoverOpen: !this.state.popoverOpen
        });
    }
    
    getGigsFromApiAsync() {
        const todayAsString = getTodayAsString();
        const urlPath = queryApiUrl('imp_gigs/rows?sort=date&sort_order=ASC&limit=50&filters[date][gte]=' + todayAsString + "&in[Published]=1");
        const self = this;
        // Make a request for a user with a given ID
        axios.get(urlPath)
        .then(function (response) {
            const updateGigs = gig =>  merge({collapse: false}, gig);
            const updatedGigs = map(updateGigs, response.data.data);
            self.setState({
                gigs: updatedGigs,
                gigsFetched: true,
             });
         })
        .catch(function (error) {
            log("Error fetching gigs");
            log(error);
            self.setState({gigs:[], gigsFetched: true, error: true});
        });
    }

    getReleasesFromApiAsync() {
        const todayAsString = getTodayAsString();
        const urlPath = queryApiUrl('imp_releases/rows?sort=date&sort_order=ASC&limit=50&filters[date][gte]=' + todayAsString + "&in[Published]=1");
        const self = this;

        axios.get(urlPath)
        .then(function (response) {
            self.setState({
                releases: response.data.data,
                releasesFetched: true,
             });
         })
        .catch(function (error) {
            log("Error fetching releases");
            log(error);
            self.setState({releases:[], releasesFetched: true, error: true});
        });

    }    

    componentWillMount() {
        this.getGigsFromApiAsync();
        this.getReleasesFromApiAsync();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.gigsFetched == true && nextState.releasesFetched == true) {
            return true;
        } else {
            return false;
        }        
    }    

    render() {
        const {gigsFetched, releasesFetched, gigs, releases} = this.state;
        const {location, bottom} = this.props;

        if (gigsFetched != true || releasesFetched != true) {
            return false;
        }
        var searchAndTodayTab = undefined; 
        if (bottom) {
            searchAndTodayTab = 
                <div className="today-tab-fixed">
                <Dropdown isOpen={this.state.popoverOpen} toggle={this.toggle} >
                <DropdownToggle caret>
                  Nyt
                </DropdownToggle>
                <DropdownMenu  className="now-menu">
                  <DropdownItem header className="now-items">
                  <TodayElement all={this.context.layoutState} gigs={gigs} releases={releases} mobile={true} onClick={this.toggle}/>

                  </DropdownItem>
                </DropdownMenu>
                </Dropdown>                

                </div>;
        } else {
            searchAndTodayTab = 
                <div>
                    <SearchElement/>
                    <TodayElement all={this.context.layoutState} gigs={gigs} releases={releases} mobile={false}/>
                </div>;
        }
/*
                    <Button className="today-button"  id='todayPopover' size="sm" onClick={this.toggle}>
                        <FontAwesome name="search" className="search-today"/>
                    </Button>
                    <Collapse isOpen={this.state.popoverOpen}>
                        <div className="collapsed-open">
                            <SearchElement/>
                            <TodayElement all={this.context.layoutState} gigs={gigs} releases={releases} name={name}/>
                        </div>
                    </Collapse>


                    */

        return (
            <div>
                {searchAndTodayTab}
                <SomeElement/>

                <div key="ma_17" className="mainospaikka17">
                    <AdPlacer key="ad_17" place={17} pageWidth={300} pageHeight={250}/>
                </div>
                <div key="ma_7" className="mainospaikka7">
                    <AdPlacer key="ad_7" place={7} pageWidth={300} pageHeight={250}/>
                </div>
                <div key="ma_8" className="mainospaikka8">
                    <AdPlacer key="ad_8" place={8} pageWidth={300} pageHeight={250}/>
                </div>
                <div key="ma_9" className="mainospaikka9">
                    <AdPlacer key="ad_9" place={9} pageWidth={300} pageHeight={250}/>
                </div>
                <div key="ma_11" className="mainospaikka11">
                    <AdPlacer key="ad_11" place={11} pageWidth={300} pageHeight={250}/>
                </div>
                <div key="ma_18" className="mainospaikka18">
                    <AdPlacer key="ad_18" place={18} pageWidth={300} pageHeight={250}/>
                </div>
                <div key="ma_10" className="mainospaikka10">
                    <AdPlacer key="ad_10" place={10} pageWidth={300} pageHeight={600}/>
                </div>
                
            </div>
        );
    }
}

Today.contextTypes = {
    layoutState: PropTypes.object
};

Today.propTypes = {
    location: PropTypes.object,
    bottom: PropTypes.bool,
};

export default Today;
