"use strict";
import '../../style/news.css';
import React from 'react';
import PropTypes from 'prop-types'
import Dimensions from 'react-dimensions';
import { Card,  CardHeader, CardFooter, CardBody } from 'reactstrap';
import classnames from 'classnames';

import { log, createDangerous, createMarkup, authorLink, facebook, dateConverted, dateDisplayer, serverAddress } from '../general';
import ShareButtons from './share_buttons';
import PublishingInfo from './publishing_info';
import AdPlacer from './ad_placer';


class NewsDetailPage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {location, news, containerWidth} = this.props;
        // log(location);

        if (this.props.news == undefined) {
            return (
                <div>
                    <h3>Uutista ei löytynyt</h3>
                </div>
            );
        }

        const ownurl = serverAddress() + location.url;
        const messageToShare = "#Imperiumi uutisoi " + this.props.news.topic + ": ";

        const header = () => {
            return (
                <div className="news-detail-head">
                    {this.props.news.topic}
                </div>
            );
        };
        const headerText = header(); 
        const footer = () => {
            return (
                <div className="news-detail-footer">
                    {dateDisplayer(dateConverted(this.props.news.date))}
                </div>
            );
            
        }

        const footerText = footer();
        const text = createMarkup(this.props.news.news)
        const element = createDangerous(text);
        const news_image = "/images/newsimages/" + this.props.news.so_image;
        const headerImage = this.props.news.so_image.length > 0 ? <img className="news-detailed-header-image" src={news_image}/> : undefined;

        const mainos16 = classnames('mainos', 'paikka_16');     
        const mainos21 = classnames('mainos', 'paikka_21');     
        const mainos22 = classnames('mainos', 'paikka_22');     

        return (
            <div>
                <Card>
                    <CardHeader className="my-card-header">
                        {headerText}
                    </CardHeader>
                    <CardBody>
                        <PublishingInfo 
                            date={this.props.news.date}
                            />
                        <ShareButtons 
                            width={containerWidth}
                            performer={"not available"}
                            message={messageToShare}
                            currentUrl={ownurl} 
                        />   
                        <div className="news-detail-body">
                            {element}       
                            {headerImage}
                        </div>
                    </CardBody>
                </Card>
                <div key="ma_16" className={mainos16}>
                    <AdPlacer place={16} pageWidth={containerWidth} pageHeight={400} marginLeft={15} marginRight={15}/>
                </div>
                <div key="ma_21" className={mainos21}>
                    <AdPlacer place={21} pageWidth={containerWidth} pageHeight={400} marginLeft={15} marginRight={15}/>
                    </div>
                <div key="ma_22" className={mainos22}>
                    <AdPlacer place={22} pageWidth={containerWidth} pageHeight={400} marginLeft={15} marginRight={15}/>
                </div>
            </div>                
        );
    }    
}

NewsDetailPage.propType = {
    news: PropTypes.object.isRequired,
    location: PropTypes.object
};

export default Dimensions()(NewsDetailPage);