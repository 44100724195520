'use strict';
import "../../style/headlines.css";
import React from 'react';
import PropTypes from 'prop-types'
import R from 'ramda';
import axios from 'axios';
import HeadlinesPage from '../components/headlines_page';
import { httpRequest, queryApiUrl, log } from '../general';

class Headlines extends React.Component {
    constructor() {
        super();
        this.state = { headlines: undefined, fetched: false, error: false };
    }
    
    getHeadlinesFromApiAsync() {
        const urlPath = queryApiUrl('imp_news/rows?sort=date&sort_order=DESC&limit=5&in[Standout]=1&in[Published]=1');
        const self = this;
        // Make a request for a user with a given ID
        axios.get(urlPath)
        .then(function (response) {
            log(response.data);
            self.setState({
                headlines: response.data.data, 
                fetched: true,
             });
         })
        .catch(function (error) {
            log("Error fetching headlines");
            log(error);
            self.setState({headlines:[], fetched: true, error: true});
        });        
    }

    componentWillMount() {
        this.getHeadlinesFromApiAsync();
    }
    
    shouldComponentUpdate(nextProps, nextState) {
        return nextState.fetched;
    }

    render() {
        
        if (this.state.headlines == undefined) {
            return false;
        }
        
        return (
            <div className="headlines-page">
                <HeadlinesPage windowWidth={this.props.windowWidth} headlines={this.state.headlines} news={this.context.layoutState.news}/>
            </div>
        );
    }
}

Headlines.contextTypes = {
    layoutState: PropTypes.object
};
Headlines.propTypes = {
    windowWidth: PropTypes.number,
    windowHeight: PropTypes.number,
};

export default Headlines;
