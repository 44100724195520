"use strict";
import '../../style/news.css';
import React from 'react';
import PropTypes from 'prop-types'
import { serverAddress, paragraphOrMaxLength, paragraphRemoveImagesAndVideos, createDangerous,createMarkup, dateDisplayer, dateConverted, paragraphSkipImages } from '../general';
import { Card,  CardHeader, CardFooter, CardBody, Media } from 'reactstrap';

import { Link } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';


class NewsLine extends React.Component{
    constructor(props, context) {
        super(props, context);
    }

    render() {
        const max_length_text = 900;

        const textWithoutStartImage = paragraphSkipImages(this.props.news.news);
        const text = paragraphRemoveImagesAndVideos(textWithoutStartImage);
        const paragraph = paragraphOrMaxLength(text, max_length_text);
        const paragraphMarkup = createMarkup(paragraph);
        const element = createDangerous(paragraphMarkup);

        const ownurl = serverAddress() + "/news/" + this.props.news.id;
        const messageToShare = "#Imperiumi uutisoi " + this.props.news.topic + ": ";

        const news_image = "/images/newsimages/" + this.props.news.so_image;
        const header = this.props.news.topic;
        const news_id = "/news/" + this.props.page + "/" + this.props.news.id;
            
        const headerPanel = (news_id, header) => {
            return (
                <div>
                    <div className="news-header">
                    <Link className="news-list-header" to={ news_id }>
                        {header}
                    </Link>
                    </div>
                    {dateDisplayer(dateConverted(this.props.news.date))}
                </div>                
            )
        }
        
        const headerImage = this.props.news.so_image.length > 0 ? <Link to={news_id}><img className="news-header-image" src={news_image}/></Link> : undefined;

        return (
            <div className="news-body">
                <Card>
                    <CardHeader>
                        {headerPanel(news_id, header)}
                    </CardHeader>
                    <CardBody>
                        <div>
                            {headerImage}
                        </div>

                        <div className="news-list">
                            {element}
                        </div>
                        <Link className="news-list-read-more" to={ news_id }>
                            Lue lisää
                        </Link>                        
                    </CardBody>
                </Card> 
            </div>
        );
    }
}

NewsLine.propType = {
    news: PropTypes.object.isRequired,
    page: PropTypes.number,
};


export default NewsLine;
