"use strict";
import '../../style/review.css';
import '../../style/share.css';
import '../../style/demos.css';

import React from 'react';
import PropTypes from 'prop-types';
import { Card, Button, CardHeader, CardFooter, CardBody,
    CardTitle, CardText } from 'reactstrap';
import { Link } from 'react-router-dom';
import Dimensions from 'react-dimensions';
import classnames from 'classnames';

import { log, scoreDisplayer, LeftNavButton, RightNavButton, createMarkup, createDangerous, serverAddress } from '../general';
import ShareButtons from './share_buttons';
import PublishingInfo from './publishing_info';

import he from 'he';
import AdPlacer from './ad_placer';

class DemoReviewsDetailPage extends React.Component {
    constructor(props) {
        super(props);
        this.linkClick = this.linkClick.bind(this);
    }

    linkClick = () => {
        // log(this.props);        
    };     

    render() {
        const {location, review, containerWidth} = this.props;
        // log(location);
        const ownurl = serverAddress() + location.url;
        const messageToShare = "Imperiumi arvosteli julkaisun " + review.performer + " - " + review.demoname + ": ";

        if (review == undefined) {
            return (
                <div>
                    <h3>Demoarvostelua ei löytynyt</h3>
                </div>
            );
        }
    
        const performerText = he.decode(review.performer);
        const albumText = he.decode(review.demoname); 

        
        const header = () => {
            return (
                <div className="demo-header">
                    <div className="demo-header-info">
                        <div className="performer-name">
                        {performerText}
                        </div>
                        <div className="demo-name">
                        {albumText}
                        </div>        
                    </div>
                    <div className="demo-score">
                        {scoreDisplayer(review.points, 18)}
                    </div>
            
                </div>
            );
        };

        const headerText = header(); 
        const mainos14 = classnames('mainos', 'paikka_14');     
        const mainos23 = classnames('mainos', 'paikka_23');     
        const mainos24 = classnames('mainos', 'paikka_24');     

        return (
            <div className="demo-detailed-review">
                <Card>
                    <CardHeader className="my-card-header">
                        {headerText}
                    </CardHeader>
                    <CardBody>
                        <PublishingInfo 
                            authorName={review.reviewer}
                            date={review.date}
                        />                     
                        <div className="demo-review-share-buttons-right">
                            <ShareButtons 
                                width={this.props.containerWidth}
                                performer={review.performer}
                                message={messageToShare}
                                currentUrl={ownurl} 
                                bandLink={review.link} 
                            />                                
                            </div>                    
                        <div className="demo-detailed-body">
                            {createDangerous(createMarkup(review.review))}
                        </div> 
                    </CardBody>
                </Card>
                <div key="ma_14" className={mainos14}>
                    <AdPlacer place={14} pageWidth={containerWidth} pageHeight={400} marginLeft={15} marginRight={15}/>
                </div>
                <div key="ma_23" className={mainos23}>
                    <AdPlacer place={23} pageWidth={containerWidth} pageHeight={400} marginLeft={15} marginRight={15}/>
                </div>
                <div key="ma_24" className={mainos24}>
                    <AdPlacer place={24} pageWidth={containerWidth} pageHeight={400} marginLeft={15} marginRight={15}/>
                </div>
            </div>
        );
    }    
}

DemoReviewsDetailPage.propType = {
    review: PropTypes.object.isRequired,
    location: PropTypes.object,
};

export default Dimensions()(DemoReviewsDetailPage);

