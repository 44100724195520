
"use strict";
import '../../style/search.css';
import React from 'react';
import PropTypes from 'prop-types';
import ReactImageFallback from 'react-image-fallback';
import { slice } from 'ramda';

import { Media } from 'reactstrap';
import he from 'he';

import { log, goTo, dateConverted, dateDisplayer } from '../general';
import PaginationComponent from './pagination';


class SearchResultInterviews extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            currentPage: 1,
            totalAmount: props.results.length,
            numberOfItems: 30,
        }
        this.handleSelect = this.handleSelect.bind(this);

    }

    handleSelect(eventKey) {
        const { numberOfItems } = this.state;
        const { results } = this.props;
        const page = parseInt(eventKey.selected);
        window.scrollTo(0,0);
        const items = slice(page * numberOfItems, page * numberOfItems + numberOfItems, results);
        this.setState({currentPage: page, items: items});
    }

    componentWillReceiveProps(nextProps) {
        const { results } = nextProps;
        const { numberOfItems } = this.state; 
        var items = [];
        if (results != undefined && results.length > 0) {
            items = slice(0, numberOfItems, results);
        }
        this.setState({items: items});
    }


    render() {
        const {items, totalAmount, currentPage, numberOfItems}  = this.state;
        
        if (items == undefined)
            return false;

        const currentPageAsNumber = parseInt(currentPage) -1;
        
        const results = items.map((interview, index) => {
            const article_image =  interview.indeximage != undefined ? "/images/interviewimages/" + interview.indeximage : undefined;
            const article_id = "/articles/1/" + interview.id;
            const dateText = dateDisplayer(dateConverted(interview.date));
            
            return (
                <div key={index} className="search-result-line search-articles">
                    {goTo(article_id,
                        <Media>
                            <Media left className="search-interview-left">
                                <ReactImageFallback
                                src={article_image}
                                fallbackImage="/images/default_article_card.png"
                                className="search-article-image" />
                            </Media>                                
                            <Media body>
                                <Media heading  className="media-header">
                                {he.decode(interview.performer)}
                                </Media>
                                {he.decode(interview.ingres)}
                                {dateText}
                            </Media>
                        </Media>                             
                    )}
                    <hr/>
                </div>
            );
        });
        
        return (
            <div className="search-results">
                {results}
                <div className="search-pagination">
                    <PaginationComponent  
                        totalAmount={totalAmount}
                        numberOfElementsPerPage={numberOfItems}
                        currentPage={currentPageAsNumber}
                        handleSelect={this.handleSelect} />
                </div>
            </div>
        );
    }
}

SearchResultInterviews.propType = {
    results: PropTypes.array.isRequired
};


export default SearchResultInterviews;