"use strict";
import '../../style/other_review.css';
import React from 'react';
import PropTypes from 'prop-types';
import ReactImageFallback from 'react-image-fallback';
import {browserHistory} from 'react-router-dom';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import ElementCard from './element_card';
import { goTo, createRightSideReview, authorLink, paragraphRemoveImagesAndVideos, periodOrMaxLength, createDangerous, log, createMarkup } from '../general';

class OtherReviewLine extends React.Component{
    constructor(props, context) {
        super(props, context);
    }

    render() {
        const xlValue = 4;
        const lgValue = 4;
        const mdValue = 6;
        const smValue = 6;
        const xsValue = 12;
        

        const text = paragraphRemoveImagesAndVideos(this.props.review.review);
        const text_shorted = periodOrMaxLength(text, 300);
        const paragraphMarkup = createMarkup(text_shorted);
        const element = createDangerous(paragraphMarkup);
        const imageName = "/images/bookcovers/" + this.props.review.coverart;
        const review_id = "/otherreviews/" + this.props.page + "/" +  this.props.review.id;
        const reviewer = this.props.review.reviewer ? authorLink(this.props.review.reviewer) : undefined;

        return (
            <Col key={this.props.review.id} xl={xlValue} lg={lgValue} md={mdValue} sm={smValue} xs={xsValue}>
                {goTo(review_id,
                    <ElementCard keyName={this.props.review.id.toString()} image={imageName} link={review_id} performer={this.props.review.title} record={this.props.review.director} points={this.props.review.points}
                        text={element}  date={this.props.review.date} reviewer={reviewer} no_text={false} image_fix="fix-height"/>
                )}
            </Col>            
        );
    }
}

OtherReviewLine.propType = {
    review: PropTypes.object.isRequired,
    page: PropTypes.number,
};


export default OtherReviewLine;
