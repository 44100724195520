"use strict";
import '../../style/playlists.css';
import React from 'react';
import PropTypes from 'prop-types'
import PlaylistsList from './playlists_list';
import { log } from '../general';

class PlaylistsPage extends React.Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        return (
            <PlaylistsList playlists={this.props.playlists} page={this.props.page}/>
        );
    }    
}

PlaylistsPage.propType = {
    playlist: PropTypes.array.isRequired,
    page: PropTypes.number,
};

export default PlaylistsPage;