"use strict";
import '../../style/videos.css';
import React from 'react';
import PropTypes from 'prop-types';
import ReactImageFallback from 'react-image-fallback';
import {browserHistory} from 'react-router-dom';
import { Col } from 'reactstrap';
import ElementCard from './element_card';

import { log, goTo, httpRequest } from '../general';

class VideosLine extends React.Component{
    constructor(props, context) {
        super(props, context);
        this.linkClick = this.linkClick.bind(this);
    }

    linkClick = () => {        
        this.props.playVideo(this.props.video.id);
    }; 
    
    vimeoLoadingThumb(id) {    
        const url = "https://vimeo.com/api/v2/video/" + id + ".json";
        const request = httpRequest(url);     

        const vimeoData = fetch(request)
            .then((response) => {
                return response.json(); 
            })
            .then((responseJson) => {
                return responseJson;
            })
            .catch((error) => {
                return undefined;
            });               

    };

    render() {
        var xlValue = 4;
        var lgValue = 6;
        var mdValue = 6;
        var smValue = 6;
        var xsValue = 12;
        var max_length_text = 400;
        if (this.props.video.promote) {
            var xlValue = 12;
            var lgValue = 12;
            var mdValue = 12;
            var smValue = 12;
            var xsValue = 12;
            
        }

        var videothumb;
        var videoProvider;
        switch(this.props.video.type) {
            default:
            case 0: // youtube
                videothumb = "https://img.youtube.com/vi/" + this.props.video.url + "/hqdefault.jpg";
                videoProvider = "Youtube";
                break;
            case 1: // myspace?
                videoProvider = "Myspace";            
                break;
            case 2: // vimeo
                videothumb = this.vimeoLoadingThumb(this.props.video.url);
                videoProvider = "Vimeo";
                break;
        }
        const noText = this.props.video.ingressiteksti != undefined ? true :  false; 
        const text = <p>{this.props.video.ingressiteksti}</p>
        const videoLink = "/" + this.props.videoId + "/" + this.props.page + "/" + this.props.video.id;
        
        return (
            <Col key={this.props.video.id} xl={xlValue} lg={lgValue} md={mdValue} sm={smValue} xs={xsValue}>
                {goTo(videoLink,
                    <ElementCard keyName={this.props.video.id.toString()} image={videothumb} link={this.props.videoId} text={text}
                        news={true} performer={this.props.video.title} date={this.props.video.date} reviewer={undefined}  promote={this.props.video.promote}/>
                , this.linkClick)}
            </Col>            
        );
    }
}

VideosLine.propType = {
    video: PropTypes.object.isRequired,
    videoId: PropTypes.string.isRequired,
    playVideo: PropTypes.object.isRequired,
    page: PropTypes.number,
};


export default VideosLine;
