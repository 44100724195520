"use strict";
import "../../style/share.css";
import React from 'react';
import {split} from 'ramda';
import PropTypes from 'prop-types';
import { Button, Collapse, Card, CardBody, CardHeader, CardFooter, ButtonDropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';

import ReactImageFallback from 'react-image-fallback';
import FontAwesome from 'react-fontawesome';
import {log, authorLink, photographerLink, dateDisplayer, dateConverted } from '../general';

class PublishingInfo extends React.Component {
    constructor(props) {
        super(props);
        this.toggleAuthors = this.toggleAuthors.bind(this);
        this.state = { collapseAuthors: false };

    }

    toggleAuthors() {
        // update state
        this.setState({
            'collapseAuthors' : !this.state.collapseAuthors,
        });        
        
    } 

    render() {
        const authors = this.props.authorName != undefined ? split(/, | \/ | & /gm, this.props.authorName) : undefined;
        const photographers = this.props.photographerName != undefined ? split(/, | \/ | & /gm, this.props.photographerName) : undefined;
        
        var authorText = undefined;
        var photographerText = undefined;

        if (authors != undefined && authors.length > 1 || photographers != undefined && photographers.length > 1) {
            
            const listOfAuthors = authors === undefined ? undefined : authors.map((author,index) => {
                return (
                    <DropdownItem className="author-list-item">{authorLink(author)}</DropdownItem>
                )
            });
            const listOfPhotographers = photographers === undefined ? undefined : photographers.map((item,index) => {
                return (
                    <DropdownItem className="author-list-item">{photographerLink(item)}</DropdownItem>
                )
            });
            authorText = [
                <ButtonDropdown direction="down" className="author-button" isOpen={this.state.collapseAuthors} toggle={this.toggleAuthors}>
                    <DropdownToggle className="author-menu-button" caret size="sm" color="secondary">
                        Tekijät
                    </DropdownToggle>
                    <DropdownMenu className="author-menu">
                        {listOfAuthors}
                        {listOfPhotographers}
                    </DropdownMenu>
                </ButtonDropdown>
            ];                   
        }
        else {
            authorText = this.props.authorName != undefined ? authorLink(this.props.authorName) : undefined;
            photographerText = this.props.photographerName != undefined ? photographerLink(this.props.photographerName) : undefined;                         
        }

        const dateText = this.props.date ? dateDisplayer(dateConverted(this.props.date)) : undefined;

        return (
            <div className="publishing-info">
                {dateText}
                <div className="author">
                    {authorText}
                    {photographerText}
                </div>
            </div>
        );
    }   
}


PublishingInfo.propTypes = {    
    date : PropTypes.string,
    authorName: PropTypes.string,
    photographerName: PropTypes.string,
};


export default PublishingInfo;