'use strict';
import React from 'react';
import PropTypes from 'prop-types'
import { RotateLoader } from 'react-spinners';
import { uniq, concat, slice, split} from 'ramda';
import axios from 'axios';
import { queryApiUrl, httpRequest, log } from '../general';
import SearchPage from '../components/search_page';

class SpecialSearch extends React.Component {
    constructor() {
        super();
        this.state = { results: [], totalAmount: 0, fetched: false, error: false, tooShort: false };
    }
    
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.match.params.text != this.props.match.params.text) {
            this.state.fetched = false;
            this.startSearch(nextProps.match.params.text);

        }
        
        if (nextState.fetched == true) {
            return true;
        } 
        
        return false;
    }

    startSearch(table, column, text) {
        const searchSpecial = (table, column, searchString) => {
            const urlPath = queryApiUrl( table + '/rows?sort=date&sort_order=DESC&limit=-1&filters['+ column +'][contains]=' + searchString + '&in[published]=1');
            return axios.get(urlPath);
        }

        const self = this;
        const tables = split('|', table);
        const searches = [];
        tables.forEach((currentTable) => {
            if (column === 'reviewer' && currentTable == 'imp_interviews') {
                searches.push(searchSpecial(currentTable, "author", text));
                searches.push(searchSpecial(currentTable, "photos", text));
            } else {
                searches.push(searchSpecial(currentTable, column, text));
            }
        });

        log("searches length " + searches.length);
        axios.all(            
                searches
            )
            .then(axios.spread((...args) => { 
                window.scrollTo(0, 0);
                
                const state = {};
                for (let i = 0; i < args.length; i++) {

                    if (args[i].data.meta.table == "imp_albums") {
                        state.reviews = args[i].data.data;
                    }
                    if (args[i].data.meta.table == "imp_demos") {
                        state.demos = args[i].data.data;
                    }
                    if (args[i].data.meta.table == "imp_booksmovies") {
                        state.others = args[i].data.data;
                    }
                    if (args[i].data.meta.table == "imp_interviews") {
                        state.interviews = uniq(concat(args[i].data.data, state.interviews || []));
                    }
                }
                self.setState({
                    fetched: true, 
                    ...state});
            })            
        );            
    }

    componentWillMount() {
        const searchString = this.props.match.params.text;
        if (searchString.length > 2) {
            this.startSearch(this.props.match.params.table, this.props.match.params.column, this.props.match.params.text);
        } else {
            this.setState({tooShort: true});
        }
        window.scrollTo(0, 0);        
    }    
     
    render() {
        if (this.state.fetched == false) {
            return (
                <div className="search-page">
                    <RotateLoader color="#4C0001" loading={!this.state.fetched}/>
                </div>
            );
        } 

        if (this.state.tooShort == true) {
            return (
                <div className="search-page">
                    Käytä vähintään kolmea kirjainta.
                </div>
            );
        }

        return (
            <div className="search-page">
                <SearchPage 
                    fetched = {this.state.fetched}
                    reviews = {this.state.reviews}
                    others = {this.state.others}
                    interviews = {this.state.interviews}
                    demos = {this.state.demos}
                    />
            </div>
        );
    }
}

SpecialSearch.propTypes = {
    windowWidth: PropTypes.number,
    windowHeight: PropTypes.number,
};

export default SpecialSearch;
