"use strict";
import "../../style/today.css";
import React from 'react';
import PropTypes from 'prop-types'
import {  Badge, Button } from "reactstrap";
import FontAwesome from 'react-fontawesome';
import he from 'he';
import { concat, slice } from 'ramda';
import { log, goTo, todayCalender } from '../general';

class Latest extends React.Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if ( nextProps.reviews != undefined &&
             nextProps.interviews != undefined &&
             nextProps.demos != undefined &&
             nextProps.others != undefined) {
            return true; 
        }
        return false;
    }

    render() {

        const {reviews, interviews, demos, others, pages, onClick} = this.props;
       
        var all = concat(reviews, interviews);
        all = concat(all, demos);
        all = concat(all, others);
        all = concat(all, pages);

        
        all =  all.sort((a,b) => {
            return new Date(b.date) - new Date(a.date);
        })

        all = slice(0, 35, all);
        // log(all)
        
        const header = entry => { 
            const entryday  = todayCalender(entry);
            var title;
            var performer;
            var record = undefined;
            var link = undefined;
            if (entry.hasOwnProperty('director')) {
                log(entry);
                title = <Badge color="dark" pill>Arvostelu</Badge>
                performer = he.decode(entry.title);
                link = "/otherreviews/1/" + entry.id;
            } else if (entry.hasOwnProperty('demoname')) {
                title = <Badge color="dark" pill>Demoarvostelu</Badge>
                performer = he.decode(entry.performer);
                record = he.decode(entry.demoname);
                link = "/demos/1/" + entry.id;
            } else if (entry.hasOwnProperty('record')) {
                title = <Badge color="dark" pill>Levyarvostelu</Badge>
                performer = he.decode(entry.performer);
                record = he.decode(entry.record);
                link = "/reviews/1/" + entry.id;
            } else if (entry.hasOwnProperty("ingres")) {
                title = <Badge color="dark" pill>Artikkeli</Badge>
                performer = he.decode(entry.performer);
                link = "/articles/1/" + entry.id;
            } else {
                title = <Badge color="dark" pill>Muu juttu</Badge>
                performer = he.decode(entry.title);
                link = "/pages/1/" + entry.id;
            }
            

            return (
                <div className="latest-header">
                    {entryday}
                    {goTo(link,
                    <div className="icon-right">
                        <div className="latest-header-text">
                            <div className="latest-type">
                            {title}
                            </div>
                            <div className="latest-performer">
                            <span>{performer}</span>
                            </div>
                            <div className="latest-record">
                            <span>{record}</span>
                            </div>
                        </div>
                    </div>
                    , onClick)}
                </div>
            ); 
        };


        const latest = all.map(entry => {
            const headerText = header(entry);

            // <Button color="secondary" style={{ marginBottom: '0.5rem', width: '100%', 'maxWidth': '300px', 'textAlign': 'left' }}>{headerText}</Button>

            return [
                <div className="latest-entry">
                    {headerText}
                </div>
            ];
        });
        
        return (
            <div className="latest">
                {latest}
            </div>
        );
    }       
}

Latest.propTypes = {
    reviews: PropTypes.array.isRequired,
    interviews: PropTypes.array.isRequired,
    demos: PropTypes.array.isRequired,
    others: PropTypes.array.isRequired,
    pages: PropTypes.array.isRequired,
    onClick: PropTypes.func,
};


export default Latest;