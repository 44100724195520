"use strict";
import '../../style/videos.css';
import React from 'react';
import PropTypes from 'prop-types'
import VideosList from './videos_list';
import { log } from '../general';

class VideosPage extends React.Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        return (
            <VideosList videos={this.props.videos} playVideo={this.props.playVideo} videoId={this.props.videoId} page={this.props.page}/>
        );
    }    
}

VideosPage.propType = {
    videos: PropTypes.array.isRequired,
    videoId: PropTypes.string.isRequired,    
    playVideo: PropTypes.func.isRequired,
    page: PropTypes.number,
};

export default VideosPage;