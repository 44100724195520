'use strict';
import "../../style/radios.css";
import React  from 'react';
import PropTypes from 'prop-types';
import { RotateLoader } from 'react-spinners';
import axios from 'axios';
import Lightbox from 'lightbox-react';
import RadioPage from '../components/radio_page';
import PaginationComponent from '../components/pagination'
import { httpRequest, queryApiUrl, log } from '../general';

class Dtp extends React.Component {
    constructor() {
        super();
        this.state = { 
            radios: [], 
            totalAmount: 0, 
            fetched: false,
            error: false,
        };
    }
    getRadiosFromApiAsync(state) {
        const urlPath = queryApiUrl('imp_DTPRadio/rows?sort=ID&sort_order=DESC&limit=-1');
        const self = this;
        // Make a request for a user with a given ID
        axios.get(urlPath)
        .then(function (response) {
            window.scrollTo(0, 0);            
            self.setState({
                radios: response.data.data,
                totalAmount: response.data.meta.total_entries, 
                fetched: true,
             });
         })
        .catch(function (error) {
            log("Error fetching DTP radios");
            log(error);
            self.setState({radios:[], fetched: true, error: true});
        });
               
    }

    componentWillMount() {
        this.getRadiosFromApiAsync(this.state);
        window.scrollTo(0, 0);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if ( nextState.currentPage != this.state.currentPage ) {
            this.getRadiosFromApiAsync(nextState);
            return false; 
        }
        if (nextState.fetched == true && this.state.radios.length > 0) {
 
            return true;
        } else {
            if (nextState.fetched == true && nextState.error == true)
                return true;

            this.getRadiosFromApiAsync(nextState);
            return false;
        }
    }
    

    render() {
        if (this.state.fetched == false) {
            return (
                <div className="videos-page-fixed searching">
                    <RotateLoader color="#4C0001" loading={!this.state.fetched}/>
                </div>            
            )
        }
        if (this.state.error == true) {
            return (
                <div className="videos-page">
                    <h3>Tietokanta rikki...</h3>
                </div>
            )
        }
        return (
            <div className="videos-page">
                <RadioPage radios={this.state.radios} location={this.props.match}/>
            </div>
        );
    }
}

/*
                {isOpen &&
                    <Lightbox className="lightbox"
                        enableZoom={false}
                        clickOutsideToClose={true}

                        imageTitle={videoNames[photoIndex]}
                        mainSrc={playerVideos[photoIndex]}
                        nextSrc={playerVideos[(photoIndex + 1) % playerVideos.length]}
                        prevSrc={playerVideos[(photoIndex + playerVideos.length - 1) % playerVideos.length]}

                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() => this.setState({
                            photoIndex: (photoIndex + playerVideos.length - 1) % playerVideos.length,
                        })}
                        onMoveNextRequest={() => this.setState({
                            photoIndex: (photoIndex + 1) % playerVideos.length,
                        })}
                    />
                }
*/

Dtp.propTypes = {
    match: PropTypes.object
};

export default Dtp;
