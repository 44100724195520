"use strict";
import "../../style/mainpage.css";
import React from 'react';
import PropTypes from 'prop-types'
import Headlines from '../containers/headlines';
import Head from '../containers/headpage';

class MainPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width:  0,
            height: 0
        }
        this.updateDimensions = this.updateDimensions.bind(this);        
    }

    /**
     * Calculate & Update state of new dimensions
     */
    updateDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    /**
     * Add event listener
     */
    componentWillMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    /**
     * Remove event listener
     */
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }    


    render() {

        return (
            <div className="main-page" >
                <Headlines windowWidth={this.state.width} windowHeight={this.state.height} />
                <Head location={this.props.location} windowWidth={this.state.width} windowHeight={this.state.height}/>
            </div>
        );
    }   
}

MainPage.contextTypes = {
    layoutState: PropTypes.object
};

MainPage.propTypes = {
    location: PropTypes.object.isRequired,
};


export default MainPage;