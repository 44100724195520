'use strict';
import "../../style/news.css";
import React  from 'react';
import {withRouter} from 'react-router-dom';
import { RotateLoader } from 'react-spinners';
import axios from 'axios';
import NewsPage from '../components/news_page';
import PaginationComponent from '../components/pagination'
import { httpRequest, queryApiUrl, log } from '../general';
import { eqProps } from 'ramda';

class News extends React.Component {
    constructor() {
        super();
        this.handleSelect = this.handleSelect.bind(this);        
        this.state = { news: [], totalAmount: 0, currentPage: 1, numberOfNews: 10, fetched: false, error: false };
    }

    handleSelect(eventKey) {
        const page = parseInt(eventKey.selected);
        const link = "/news/" + (page + 1);
        this.props.history.push(link);
        this.setState({fetched: false});
    }    

    getNewsFromApiAsync(state, props) {
        const currentPage = props.match.params.page;
        const currentPageAsNumber = parseInt(currentPage);
        const offset = (currentPageAsNumber - 1) * state.numberOfNews;
        var left;
        if (state.totalAmount == 0)
            left = state.numberOfNews;
        else
            left = state.totalAmount - offset;        
        const limit = left >= state.numberOfNews ? state.numberOfNews : left;
        const urlPath = queryApiUrl('imp_news/rows?sort=timestamp&sort_order=DESC&limit=' + limit + '&offset=' + offset + '&in[published]=1');
        const self = this;
        // Make a request for a user with a given ID
        axios.get(urlPath)
        .then(function (response) {
            window.scrollTo(0, 0);            
            self.setState({
                news: response.data.data,
                totalAmount: response.data.meta.total_entries, 
                fetched: true,
                currentPage: currentPage,
             });
         })
        .catch(function (error) {
            log("Error fetching news");
            log(error);
            self.setState({news:[], fetched: true, error: true});
        });        
    }

    componentWillMount() {
        this.getNewsFromApiAsync(this.state, this.props);
        window.scrollTo(0, 0);
        
    }

    shouldComponentUpdate(nextProps, nextState) {
        const currentPage = nextProps.match.params.page;
        if ( currentPage != this.props.match.params.page ) {
            this.getNewsFromApiAsync(nextState, nextProps);
            return false; 
        }
        if (nextState.fetched == true && this.state.news.length > 0) {
            return true;
        } else {
            if (nextState.fetched == true && nextState.error == true)
                return true;

            this.getNewsFromApiAsync(nextState, nextProps);
            return false;
        }
    }

    render() {
        const currentPage = this.props.match.params.page;
        const currentPageAsNumber = parseInt(currentPage) -1;        
        if (this.state.fetched == false) {
            return (
                <div className="news-page-fixed searching">
                    <RotateLoader color="#4C0001" loading={!this.state.fetched}/>
                </div>            
            )
        }     
        return (
            <div className="news-page">
                <NewsPage news={this.state.news} page={currentPage}/>
                <PaginationComponent  
                    totalAmount={this.state.totalAmount}
                    numberOfElementsPerPage={this.state.numberOfNews}
                    currentPage={currentPageAsNumber}
                    handleSelect={this.handleSelect} />
            </div>
        );
    }
}

export default withRouter(News);