"use strict";
import "../../style/today.css";
import React from 'react';
import PropTypes from 'prop-types'
import { find, propEq, findIndex} from 'ramda';
import { Collapse, Card, CardBody, CardHeader, CardGroup, Button } from "reactstrap";
import FontAwesome from 'react-fontawesome';
import ReactImageFallback from 'react-image-fallback';
import he from 'he';
import {cdonLink, xLink, linkTo, todayCalender} from '../general';

class Releases extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
    }


    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.releases.length > 0) {
            this.setState({releases: nextProps.releases});
            return true;
        }
        return false;
    }

    toggle(id) {
        const element = find(propEq('id', id))(this.state.releases); 
        const elementId = findIndex(propEq('id', id))(this.state.releases); 
        const collapse = !element.collapse;
        const releases = this.state.releases;
        releases[elementId].collapse = collapse;
    
        // update state
        this.setState({
            releases,
        });        
        
    } 
    render() {
        const title = ( <h3 className="right-title">Tulevia julkaisuja</h3> );

        const header = item => { 
            const releaseDay = todayCalender(item);
//            const release_day  = item.date.substr(8,2);
            const performerText = he.decode(item.performer);
            const albumText = he.decode(item.record);
            return (
                <div className="gig-header">
                    {releaseDay}
                    <div className="icon-right">
                        {performerText}
                        <br/>
                        {albumText}
                    </div>
                </div>
            ); 
        };


        const releases = this.props.releases.map((release, index) => {
            const headerText = header(release);
            const performerText = release.performer;

            return [
                <div key={index}>
                    <Button color="secondary" onClick={() => this.toggle(release.id)} style={{ marginBottom: '0.5rem', width: '100%', 'textAlign': 'left' }}>{headerText}</Button>

                    <Collapse isOpen={release.collapse}>
                    <Card>
                        <CardBody>
                            <div>
                                <a href={release.link} target="_blank">{release.link}</a>
                            </div>
                        </CardBody>
                    </Card>
                    </Collapse>                
                </div>
            ];
        });

        return (
            <div className="releases">
                {releases}
            </div>
        );
    }       
}

Releases.propTypes = {
    releases: PropTypes.array.isRequired,
};


export default Releases;