"use strict";
import '../../style/news.css';
import React from 'react';
import PropTypes from 'prop-types'
import NewsLine from './news_line';

class NewsList extends React.Component{
    render() {
        var news;
        if (this.props.news) {
            news = this.props.news.map(n =>
                <NewsLine key={n.id} news={n} page={this.props.page}/>
            );
        }
        return (
            <div>           
                {news}
            </div>        
        );
    }
}

NewsList.propType = {
    news: PropTypes.array.isRequired,
    page: PropTypes.number,
};


export default NewsList;