"use strict";
import "../../style/some_element.css";
import React from 'react';
import PropTypes from 'prop-types'
import { Nav, NavLink, NavItem, UncontrolledTooltip } from 'reactstrap';
import FontAwesome from 'react-fontawesome';


class SomeElement extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const buttonSize = "3x";
        return (
            <div key="some-buttons" className="some-buttons">
                <div key="some-text" className="some-text">
                    Seuraa meitä
                </div>
                <Nav key="some-links" className="some-icons">
                    <NavLink key="facebook" target="_blank" href="https://www.facebook.com/Imperiumi/">             
                        <div className="menu-some-icon">
                            <FontAwesome name='facebook-official' size={buttonSize}/>
                        </div>                    
                    </NavLink>     
                    <NavLink key="twitter" target="_blank" href="https://twitter.com/imperiumi">             
                        <div className="menu-some-icon">
                            <FontAwesome name='twitter' size={buttonSize} className="twitter-black"/>
                        </div>
                    </NavLink>     
                    <NavLink key="instagram" target="_blank" href="https://www.instagram.com/imperiumi/">             
                        <div className="menu-some-icon">
                            <FontAwesome name='instagram' size={buttonSize}/>
                        </div>
                    </NavLink>     
                    <NavLink key="messulauta" target="_blank" href="https://www.impe.fi/phpbb">             
                        <div id="messulauta-tip">
                            <div className="menu-comments">
                                <FontAwesome name='comments' size={buttonSize}/>
                            </div> 
                            <UncontrolledTooltip placement="bottom" target="messulauta-tip">
                                Imperiumin Messulauta
                            </UncontrolledTooltip>
                        </div>
                    </NavLink> 
                </Nav>            
            </div>
        );
    }
}

export default SomeElement;