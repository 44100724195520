'use strict';
import "../../style/app.css";
import React from 'react';
import PropTypes, {instanceOf} from 'prop-types';
import { RotateLoader } from 'react-spinners';
import { withRouter } from 'react-router-dom';
import { withCookies, Cookies } from 'react-cookie';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Center from 'react-center';
import Dimensions from 'react-dimensions';
import {slice} from 'ramda';
import FontAwesome from 'react-fontawesome';
import axios from 'axios';
import classnames from 'classnames';
import {Helmet} from 'react-helmet';

import AdPlacer from '../components/ad_placer';
import Header from '../components/header';
import Footer from '../components/footer';
import ReactImageFallback from 'react-image-fallback';

// import Popup from '../components/popup';
import Today from './today';
import { queryApiUrl, log, getWeekAgoDate } from '../general';

const todayToBottom = 769;

class App extends React.Component  {
    constructor() {
        super();
        this.state = {
            width:  0,
            height: 0,
            interval : 500,
            callback: this.tick,
            duration : 2000,
            elapsed: 0,
            curtains: true,
            todayToBottom: todayToBottom,
            childrenWidth: 0,
            news: [], 
            fetchedNews: false, 
            reviews: [],
            fetchedReviews: false, 
            interviews: [], 
            fetchedInterviews: false,
            otherReviews: [],
            fetchedOtherReviews: false,
            demoReviews: [],
            fetchedDemoReviews: false,
            pages: [],
            fetchedPages: false,
            error: false,             
            ads: [],
            totalAmountAds: 0,
            fetchedAds: false,
            errorAds: false,
            adTypes: [], 
            totalAmountAdTypes: 0,
            fetchedAdTypes: false,
            errorAdTypes: false,
            showFull: false,
        }
        this.updateDimensions = this.updateDimensions.bind(this);
        this.tick = this.tick.bind(this);

    }

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    
    static childContextTypes = {
        layoutState: React.PropTypes.object
    }

    getNews() {
        const urlPath = queryApiUrl('imp_news/rows?sort=timestamp&sort_order=DESC&limit=6&in[Standout]=0&in[Published]=1');
        const self = this;
        // Make a request for a user with a given ID
        axios.get(urlPath)
        .then(function (response) {
            self.setState({
                news: response.data.data, 
                fetchedNews: true,
             });
         })
        .catch(function (error) {
            log("Error fetching news");
            log(error);
            self.setState({news:[], fetchedNews: true, error: true});
        });

    }

    getReviews() {
        // const urlPath = queryApiUrl('imp_albums/rows?sort=date&sort_order=DESC&limit=5&in[Published]=1');
        const todayAsString = getWeekAgoDate();
        const urlPath = queryApiUrl('imp_albums/rows?sort=date&sort_order=DESC&limit=84&in[Published]=1&filters[date][gte]=' + todayAsString);
        
        const self = this;
        // Make a request for a user with a given ID
        axios.get(urlPath)
        .then(function (response) {
            self.setState({
                reviews: response.data.data, 
                fetchedReviews: true,
             });
         })
        .catch(function (error) {
            log("Error fetching reviews");
            log(error);
            self.setState({reviews:[], fetchedReviews: true, error: true});
        });
        
    }


    getInterviews() {
        const urlPath = queryApiUrl('imp_interviews/rows?sort=date&sort_order=DESC&columns=id,performer,ingres,indeximage,date&limit=12&in[Published]=1');
        const self = this;
        // Make a request for a user with a given ID
        axios.get(urlPath)
        .then(function (response) {
            self.setState({
                interviews: response.data.data, 
                fetchedInterviews: true,
             });
        })
        .catch(function (error) {
            log("Error fetching interviews");
            log(error);
            self.setState({interviews:[], fetchedInterviews: true, error: true});
        });
    }

    
    getOtherReviews() {
        const urlPath = queryApiUrl('imp_booksmovies/rows?sort=date&sort_order=DESC&limit=6&in[Published]=1');
        const self = this;

        axios.get(urlPath)
        .then(function(response) {
            self.setState({
                otherReviews: response.data.data,
                fetchedOtherReviews: true,
            });
        })
        .catch(function(error) {
            log("Error fetching other reviews");
            log(error);
            self.setState({otherReviews:[], fetchedOtherReviews: true, error: true});
        });
    }
    
    getDemoReviews() {
        const urlPath = queryApiUrl('imp_demos/rows?sort=date&sort_order=DESC&limit=6&in[Published]=1');
        const self = this;
        
        axios.get(urlPath)
        .then(function(response) {
            self.setState({
                demoReviews: response.data.data,
                fetchedDemoReviews: true,
            });
        })
        .catch(function(error) {
            log("Error fetching demo reviews");
            log(error);
            self.setState({demoReviews:[], fetchedDemoReviews: true, error: true});
        });
    }    

    getPages() {
        const urlPath = queryApiUrl('imp_pages/rows?sort=id&sort_order=DESC&limit=5&in[Published]=1');
        const self = this;
        
        axios.get(urlPath)
        .then(function(response) {
            self.setState({
                pages: response.data.data,
                fetchedPages: true,
            });
        })
        .catch(function(error) {
            log("Error fetching pages");
            log(error);
            self.setState({pages:[], fetchedPages: true, error: true});
        });

    }
        

    /**
     * Fetch adtypes to used by ad renderer
     */
    getAdTypes() {
        const limit = -1;
        const urlPath = queryApiUrl('imp_ad_type/rows?sort=ad_type&sort_order=ASC');
        const self = this;
        // Make a request for a user with a given ID
        axios.get(urlPath)
        .then(function (response) {
            self.setState({
                adTypes: response.data.data,
                totalAmount: response.data.meta.total_entries, 
                fetchedAdTypes: true,
             });
         })
        .catch(function (error) {
            self.setState({adTypes:[], fetchedAdTypes: true, errorAdTypes: true});
        });        
    }
    
    getAdsFromApiAsync() {
        const urlPath = queryApiUrl('imp_ads/rows?sort=date&sort_order=DESC&in[published]=1');
        const self = this;
        // Make a request for a user with a given ID
        axios.get(urlPath)
        .then(function (response) {
            self.setState({
                ads: response.data.data,
                totalAmount: response.data.meta.total_entries, 
                fetchedAds: true,
             });
         })
        .catch(function (error) {
            self.setState({ads:undefined, fetchedAds: true, errorAds: true});
        });        
            
    }    
        
    getChildContext() {
        return {layoutState: this.state};
    }
        
    componentDidMount() {
        const {interval, curtains} = this.state;
        if (curtains) {
            this.timer = setInterval(this.tick, interval);
        }
    }

    tick() {
        const {elapsed} = this.state;
        this.setState({elapsed: elapsed + 500});
    }    
    /**
     * Calculate & Update state of new dimensions
     */
    updateDimensions() {
        var childrenWidth = window.innerWidth;
        if ( childrenWidth > todayToBottom) {
            childrenWidth = this.props.containerWidth - 300;
        }
        log("ChildrenWidth");
        log(childrenWidth);
        
        this.setState({ width: window.innerWidth, height: window.innerHeight, childrenWidth: childrenWidth });
    }


    /**
     * Add event listener
     */
    componentWillMount() {

        this.getAdTypes();                
        this.getAdsFromApiAsync();
        this.getNews();
        this.getReviews(); 
        this.getInterviews();
        this.getOtherReviews();
        this.getDemoReviews();
        this.getPages();
        const { cookies } = this.props;
        const curtains = cookies.get("curtains");
        // log(curtains);
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
        this.state = {...this.state, curtains: false /*curtains ? false: true*/ }
        // this.setState({curtains: curtains == true ? false : true})
    }

    /**
     * Remove event listener
     */
    componentWillUnmount() {
        const {curtains} = this.state;
        window.removeEventListener("resize", this.updateDimensions.bind(this));
        if (curtains)
            clearInterval(this.timer);
    }    

    shouldComponentUpdate(nextProps, nextState) {
        /*
        if ( nextState.curtains && nextState.elapsed < nextState.duration ) {
            return false; 
        }
        */
        return true;
    }    


    
    render() {
        const {showFull, curtains, width, height, elapsed, duration, childrenWidth, fetchedNews, fetchedReviews, fetchedInterviews, fetchedOtherReviews, fetchedDemoReviews, error, errorAds, errorAdTypes, fetchedAds, fetchedAdTypes, ads, adTypes} = this.state;
        const {cookies, children, location} = this.props;

        log("**** DEBUG ****");
        log(this.props.location);
        const today_to_bottom_width = todayToBottom;
        const today_to_bottom = width < today_to_bottom_width;
        const classes = classnames('content', {bottom: today_to_bottom});
        const todayClasses = classnames("today-splitter", {bottom: today_to_bottom});

        const childrenWithProps = React.Children.map(children, 
            (child, i) => {
                const childAdded =  React.cloneElement(child, {windowWidth: width, windowHeight: height, key:{i}});
                return childAdded;
            }
        );

        const page = classnames('page', {opened: curtains == false});
        if (fetchedNews == false || fetchedReviews == false || fetchedInterviews == false || fetchedOtherReviews == false || fetchedDemoReviews == false || fetchedAds == false || fetchedAdTypes == false) {
            return (<div className="stage-searching">
                <RotateLoader color="#4C0001" loading={!this.state.fetchedDemoReviews}/>
            </div>);
        }
        var breadCrumbs = [];
        var text;
        breadCrumbs.push(<BreadcrumbItem key="br_root" tag="a" href="/"><FontAwesome name="home"/></BreadcrumbItem>);
        if (location != undefined) {
            const pathToText = (location) => {
                const locationText = slice(1, location.pathname.length, location.pathname);
                text = locationText;
                if (locationText.match(/^news(.*)/)) {
                    text = "Uutiset";
                }
                if (locationText.match(/^articles(.*)/)) {
                    text = "Artikkelit";
                }
                if (locationText.match(/^demos(.*)/)) {
                    breadCrumbs.push(<BreadcrumbItem  tag="a" key="br_demos" href='/demos/1'>Arvostelut</BreadcrumbItem>);
                    text = "Demoarvostelut";
                }
                if (locationText.match(/^reviews(.*)/)) {
                    breadCrumbs.push(<BreadcrumbItem  tag="a" key="br_reviews" href='/reviews/1'>Arvostelut</BreadcrumbItem>);
                    text = "Levyarvostelut";
                }
                if (locationText.match(/^otherreviews(.*)/)) {
                    breadCrumbs.push(<BreadcrumbItem  tag="a" key="br_otherreviews" href='/otherreviews/1'>Arvostelut</BreadcrumbItem>);
                    text = "Muut arvostelut";                    
                }
                if (locationText.match(/^videos(.*)/)) {
                    breadCrumbs.push(<BreadcrumbItem  tag="a" key="br_videos" href='/videos/1'>Media</BreadcrumbItem>);                    
                    text = "Musavideot";                    
                }
                if (locationText.match(/^imptv(.*)/)) {
                    breadCrumbs.push(<BreadcrumbItem  tag="a" key="br_imptv" href={location.pathname}>Media</BreadcrumbItem>);                    
                    text = "ImperiumiTV";                    
                }
                if (locationText.match(/^rauta(.*)/)) {
                    breadCrumbs.push(<BreadcrumbItem  tag="a" key="br_rauta" href={location.pathname}>Media</BreadcrumbItem>);                    
                    text = "Rauta";                    
                }
                if (locationText.match(/^dtp(.*)/)) {
                    breadCrumbs.push(<BreadcrumbItem  tag="a" key="br_dtp" href={location.pathname}>Media</BreadcrumbItem>);                    
                    text = "Death To Posers Radio";                    
                }
                if (locationText.match(/^playlists(.*)/)) {
                    breadCrumbs.push(<BreadcrumbItem  tag="a" key="br_playlists" href='/playlists/1'>Media</BreadcrumbItem>);                    
                    text = "Spotify-soittolistat";                    
                }
                if (locationText.match(/^profiles(.*)/)) {
                    text = "Toimitus";                    
                }
                if (locationText.match(/^search.*\/(.*)$/g)) {                    
                    breadCrumbs.push(<BreadcrumbItem tag="a" key="br_search">Hakutulokset</BreadcrumbItem>);     
                    const regex = /search.*\/(.*)$/g;
                    const result = regex.exec(locationText);
                    text = result[1]; 

                }

                if (locationText.match(/^admin\/news/)) {
                    text = "Jätä uutisvinkki";                    
                }
                if (locationText.match(/^admin\/gigs/)) {
                    text = "Jätä keikkailmoitus";   
                }
                if (locationText.match(/^pages(.*)/)) {
                    text = "Muita juttuja";                    
                }
                if (locationText.match(/^pages\/2/)) {
                    text = "Usein kysytyt kysymykset";                    
                }
                if (locationText.match(/^pages\/3/)) {
                    text = "Mediakortti";                    
                }
                if (locationText.match(/^all_gigs(.*)/)) {
                    text = "Keikat";                    
                }
                if (locationText.match(/^all_releases(.*)/)) {
                    text = "Julkaisut";                    
                }

                return text;
            } 
            
            breadCrumbs.push(<BreadcrumbItem key={location.pathname} tag="a" href={location.pathname}>  {pathToText(location)}</BreadcrumbItem>);
        }

        const mainos2 = classnames('mainos', 'paikka_2');     
        const pageurl = "https://www.impe.fi" + this.props.location.pathname;
        
        return (
            <div key="stage" className="stage">
                <Helmet>
                <title>{"Imperiumi.net / " + text}</title>
                <meta property="title" content="imperiumi.net / impe.fi"/>
                <meta name="description" content="imperiumi.net | impe.fi | arvostelut, uutiset, keikat, julkaisut, haastattelut, raportit, videot, media"/>

                <link rel="canonical" href={pageurl} />
                <meta lang="fi-FI"/>
                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="twitter:site" content="@imperiumi"/>
                <meta property="og:site_name" content="impe.fi"/>
                <meta property="og:url" content={pageurl}/>
                <meta property="og:type" content="article"/>
                <meta property="og:image" content="https://www.impe.fi/images/basic-logo.png"/>
                <meta property="twitter:image" content="https://www.impe.fi/images/basic-logo.png"/>
                <meta property="og:title" content="imperiumi.net"/>
                <meta property="twitter:title" content="imperiumi.net"/>
                <meta property="twitter:description" content="imperiumi.net | impe.fi | arvostelut, keikat, julkaisut, haastattelut, raportit, videot, media"/>
                <meta property="og:description" content="imperiumi.net | impe.fi | arvostelut, keikat, julkaisut, haastattelut, raportit, videot, media"/>

                </Helmet>
                <AdPlacer place={1} pageWidth={width} pageHeight={120}/>
                <Center >
                    <section key="section"  className={page}>
                        <Header location={  this.props.location } pageWidth={width} pageHeight={height} childrenWidth={childrenWidth}/>
                        <div key="childrens" className={classes}>
                            <div key="children" className="empty-children">
                                <Breadcrumb tag="nav">
                                    {breadCrumbs}
                                </Breadcrumb>
                                <div key="ma_2" className={mainos2}>
                                    <AdPlacer place={2} pageWidth={childrenWidth - 32} pageHeight={400} marginLeft={15} marginRight={15}/>
                                </div>
                                { childrenWithProps }
                            </div>
                            <div key="today" className={todayClasses}>
                                <Today location={ this.props.location} name={ classes } bottom={today_to_bottom}/>
                            </div>
                        </div>

                        <Footer/>
                    </section>
                </Center>
            </div>
        );
    }
};


export default Dimensions()(withRouter(withCookies(App)));
