'use strict';
import '../../style/header.css';
import React from 'react';
import Navigation from './navigation';

const Header = props => (
    <Navigation key="a" {...props} />
);

export default Header;
