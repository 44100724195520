
"use strict";
import '../../style/search.css';
import React from 'react';
import PropTypes from 'prop-types';
import { Media } from 'reactstrap';
import he from 'he';

import { goTo, log, dateDisplayer, dateConverted, todayCalenderWithYear } from '../general';

class SearchResultReleases extends React.Component{
    render() {
        
        var results;
        if (this.props.results) {

            results = this.props.results.map((release, index) => {
                
                const review_id = release.reviewlink ? "/reviews/1/" + release.reviewlink : undefined;
                // const dateText = dateDisplayer(dateConverted(release.date));
                const dateText = todayCalenderWithYear(release.date);
                return (
                    <div key={index} className="search-result-line search-releases">
                        {goTo(review_id,
                            <Media>
                                <Media left className="search-releases-left">
                                    {dateText}
                                </Media>
                                <Media body>
                                    <Media heading  className="media-header">
                                    {he.decode(release.performer)} 
                                    </Media>
                                    {he.decode(release.record)}
                                </Media>
                            </Media>                           
                        )}
                        <hr/>
                    </div>
                );
                }
            );
        }
        
        return (
            <div className="search-results">
                {results}
            </div>     
        );
    }
}

SearchResultReleases.propType = {
    results: PropTypes.array.isRequired
};


export default SearchResultReleases;