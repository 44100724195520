
"use strict";
import '../../style/search.css';
import React from 'react';
import PropTypes from 'prop-types';
import { Media } from 'reactstrap';    
import { slice } from 'ramda';

import he from 'he';

import { log, dateConverted, dateDisplayer, youtubePlayer } from '../general';
import PaginationComponent from './pagination';

class SearchResultVideos extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            currentPage: 1,
            totalAmount: props.results.length,
            numberOfItems: 5,
        }
        this.handleSelect = this.handleSelect.bind(this);

    }

    handleSelect(eventKey) {
        const { numberOfItems } = this.state;
        const { results } = this.props;
        const page = parseInt(eventKey.selected);
        window.scrollTo(0,0);
        const items = slice(page * numberOfItems, page * numberOfItems + numberOfItems, results);
        this.setState({currentPage: page, items: items});
    }

    componentWillReceiveProps(nextProps) {
        const { results } = nextProps;
        const { numberOfItems } = this.state; 
        var items = [];
        if (results != undefined && results.length > 0) {
            items = slice(0, numberOfItems, results);
        }
        this.setState({items: items});
    }
    
    render() {
        
        const {items, totalAmount, currentPage, numberOfItems}  = this.state;
        
        if (items == undefined)
            return false;

        const currentPageAsNumber = parseInt(currentPage) -1;
        
        const results = items.map((video, index) => {
            const dateText = dateDisplayer(dateConverted(video.date));
            const youtube = youtubePlayer(video.url);                
            return (
                <div key={index} className="search-result-line search-videos">
                    <Media>
                    <Media body>
                        <Media heading  className="media-header">
                        {he.decode(video.title)} 
                        </Media>
                        {dateText}
                        {youtube}
                    </Media>
                    </Media>
                    <hr/>
                </div>
            );    
        });
        
        
        return (
            <div className="search-results">
                {results}
                <div className="search-pagination">
                    <PaginationComponent  
                        totalAmount={totalAmount}
                        numberOfElementsPerPage={numberOfItems}
                        currentPage={currentPageAsNumber}
                        handleSelect={this.handleSelect} />
                </div>
            </div>
        );
    }
    
}

SearchResultVideos.propType = {
    results: PropTypes.array.isRequired
};


export default SearchResultVideos;