"use strict";
import "../../style/today.css";
import "../../style/ad.css";
import React from 'react';
import PropTypes from 'prop-types'
import { Nav, NavLink, NavItem, TabContent, TabPane, Form, FormGroup, Button, Input, UncontrolledTooltip } from 'reactstrap';
import { Link, Redirect, withRouter } from 'react-router-dom';
import classnames from 'classnames';
import FontAwesome from 'react-fontawesome';
import ScrollBars from 'react-custom-scrollbars';
import Gigs from './gigs';
import Releases from './releases';
import Latest from './latest';
import {log, improveAdsLink, updateFromEventValue, goTo} from '../general';
import releases from "./releases";


class TodayElement extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: '1',
        };        
    }

    
    toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
    }

    render() {
        const latestHeader = <div className="tab-header">Tuoreimmat</div>;
        const gigsHeader = <div className="tab-header">Keikat</div>;
        const releasesHeader = <div className="tab-header">Julkaisut</div>;
        const scroll = classnames("scroll", {"mobile": this.props.mobile == true});

        return (
            <div className="today-tabs">
                <Nav key="tabs" tabs>
                    <NavItem key="gigs-header">
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '1' })}
                            onClick={() => { this.toggle('1'); }}
                        >
                            {gigsHeader}
                        </NavLink>
                    </NavItem>
                    
                    <NavItem key="latest-header">
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '3' })}
                            onClick={() => { this.toggle('3'); }}
                        >
                            {latestHeader}
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent key="tab-contents"  activeTab={this.state.activeTab}>
                    <TabPane key="gigs" tabId="1" className={scroll}>
                        <ScrollBars renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}>
                            <Gigs gigs={this.props.gigs} bandLength={20}/>
                            <div className="all-gigs-button">
                            <Button className="songlist-button button-center" color="link" onClick={() => this.props.history.push('/all_gigs')}>Lisää</Button>
                            </div>
                        </ScrollBars>
                    </TabPane>
                    <TabPane key="latest" tabId="3" className={scroll}>
                        <ScrollBars renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}>
                            <Latest reviews={this.props.all.reviews}
                                interviews={this.props.all.interviews}
                                demos={this.props.all.demoReviews}
                                others={this.props.all.otherReviews}
                                pages={this.props.all.pages}
                                onClick={this.props.onClick}/>
                        </ScrollBars>
                    </TabPane>
                </TabContent>

            </div>
        );
    }   
}

//<NavItem key="release-header">
//<NavLink
//    className={classnames({ active: this.state.activeTab === '2' })}
//    onClick={() => { this.toggle('2'); }}
//>
//    {releasesHeader}
//</NavLink>
//</NavItem>

//<TabPane key="releases" tabId="2" className={scroll}>
//<ScrollBars renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}>
//    <Releases releases={this.props.releases}/>
//    <div className="all-releases-button">
//    <Button className="songlist-button button-center" color="link" onClick={() => this.props.history.push('/all_releases')}>Lisää</Button>
//    </div>
//</ScrollBars>
//</TabPane>


TodayElement.propTypes = {
    all: PropTypes.object.isRequired,
    gigs: PropTypes.array.isRequired,
    releases: PropTypes.array.isRequired,
    mobile: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
};


export default withRouter(TodayElement);