"use strict";
import "../../style/search.css";
import "../../style/ad.css";
import React from 'react';
import PropTypes from 'prop-types';
import { filter } from 'ramda';
import { Nav, NavLink, NavItem, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import Gigs from './gigs';

import {log, isUndefinedOrEmpty} from '../general';


class AllGigsPage extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
          activeTab: '1'
        };        
    }

    componentDidMount() {
        const monthDate = new Date();
        const monthAsNumber = monthDate.getMonth() + 1;
        this.setState({activeTab: monthAsNumber.toString()});        
    }


    toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
    }

    render() {
        const {fetched, gigs, year} = this.props;
        if (!fetched) {
            return false;
        }

        var gigsTabElem = [];
        var gigsNavElem = [];
        var keyNumber = 1;        
        const monthDate = new Date();

        //<SearchResultGigs results={gigs}/>
        
        const gigTab = (keyNumber, gigs) => { 
            return(
                <TabPane tabId={keyNumber.toString()}>
                    <Gigs gigs={gigs}/>
                </TabPane>
            )
        };
        const navLink = (keyNumber, title) => {
            return (
                <NavLink
                    className={classnames({ active: this.state.activeTab === keyNumber.toString() })}
                    onClick={() => { this.toggle(keyNumber.toString()); }}
                >
                    {title}
                </NavLink>
            );
        }

        if (!isUndefinedOrEmpty(gigs)) {
            for (let month = 0; month < 12; month++) { 
                monthDate.setMonth(month);
                monthDate.setDate(1);
                monthDate.setFullYear(year);
                const getMonthGigs = (gig) => {
                    const gigDate = new Date(gig.date);

                    if (monthDate.getFullYear() == gigDate.getFullYear() && monthDate.getMonth()  == gigDate.getMonth() ) {
                        return true;
                    }
                    return false;
                }        
                const gigMonth = filter(getMonthGigs, gigs);
                const monthAsText = monthDate.toLocaleString('fi-fi', { month: 'long' });
                const title = <div className="tab-header">{monthAsText}</div>;
                gigsTabElem.push(gigTab(keyNumber, gigMonth)); 
                gigsNavElem.push(navLink(keyNumber, title));
                keyNumber = keyNumber + 1;   
            }
        }

        return (
            <div className="all-gigs">
                <div>
                    <Nav tabs justified>
                        <NavItem>
                            {gigsNavElem[0]}
                        </NavItem>                            
                        <NavItem>
                            {gigsNavElem[1]}
                        </NavItem>                            
                        <NavItem>
                            {gigsNavElem[2]}
                        </NavItem>                            
                        <NavItem>
                            {gigsNavElem[3]}
                        </NavItem>                            
                        <NavItem>
                            {gigsNavElem[4]}
                        </NavItem>                            
                        <NavItem>
                            {gigsNavElem[5]}
                        </NavItem>                            
                        <NavItem>
                            {gigsNavElem[6]}
                        </NavItem>                            
                        <NavItem>
                            {gigsNavElem[7]}
                        </NavItem>                            
                        <NavItem>
                            {gigsNavElem[8]}
                        </NavItem>                            
                        <NavItem>
                            {gigsNavElem[9]}
                        </NavItem>                            
                        <NavItem>
                            {gigsNavElem[10]}
                        </NavItem>                            
                        <NavItem>
                            {gigsNavElem[11]}
                        </NavItem>                            
                    </Nav>
                </div>
                <TabContent activeTab={this.state.activeTab}>
                    {gigsTabElem}
                </TabContent>
            </div>
        );
    }   
}

AllGigsPage.propTypes = {
    fetched: PropTypes.bool.isRequired,
    gigs: PropTypes.array,
    year: PropTypes.string,
};


export default AllGigsPage;