'use strict';
import React  from 'react';
import PropTypes from 'prop-types';
import { RotateLoader } from 'react-spinners';
import axios from 'axios';
import ProfilesDetailPage from '../components/profiles_detail_page';
import { httpRequest, queryApiUrl, log } from '../general';

class ProfilesDetail extends React.Component {
    constructor() {
        super();
        this.state = { profiles: [], limit: -1, totalAmount: 0, fetched: false, error: false };
    }

    handleSelect = (eventKey) => {
        this.setState({
            currentPage: eventKey,
            fetched: false,
        });
    }
    
    getProfilesFromApiAsync(state) {
        const urlPath = queryApiUrl('imp_profiles/rows?sort=id&sort_order=ASC&limit=' + state.limit + '&in[published]=1');
        const self = this;
        // Make a request for a user with a given ID
        axios.get(urlPath)
        .then(function (response) {
            window.scrollTo(0, 0);
            self.setState({
                profiles: response.data.data,
                totalAmount: response.data.meta.total_entries, 
                fetched: true,
             });
         })
        .catch(function (error) {
            log("Error fetching profiles");
            log(error);
            self.setState({profiles:[], fetched: true, error: true});
        });
    }

    componentWillMount() {
        this.getProfilesFromApiAsync(this.state);
        window.scrollTo(0, 0);        
    }

    shouldComponentUpdate(nextProps, nextState) {
        if ( nextState.currentPage != this.state.currentPage ) {
            this.getProfilesFromApiAsync(nextState);
            return false; 
        }
        if (nextState.fetched == true && this.state.profiles.length > 0) {
            return true;
        } else {
            this.getProfilesFromApiAsync(nextState);
            return false;
        }
    }
    

    render() {
        if (this.state.fetched == false) {
            return (
                <div className="profiles-page searching">
                    <RotateLoader color="#4C0001" loading={!this.state.fetched}/>
                </div>            
            )
        }
        return (
            
            <div className="profiles-page">
                <ProfilesDetailPage profiles={this.state.profiles} location={this.props.match}/>
            </div>
        );
    }
}

ProfilesDetail.propTypes = {
    match: PropTypes.object
};

export default ProfilesDetail;
