"use strict";
import '../../style/review.css';
import React from 'react';
import PropTypes from 'prop-types';
import ReviewLine from './review_line';
import { log } from '../general';
import { Container, Row } from 'reactstrap';

class ReviewsList extends React.Component{
    render() {
        var reviews;
        if (this.props.reviews) {
            reviews = this.props.reviews.map(review =>
                <ReviewLine key={review.id} review={review} page={this.props.page}/>
            );
        }
        return (
            <div>
                <Container>
                    <Row className="show-grid">
                        {reviews}
                    </Row>
                </Container>            

            </div>     
        );
    }
}

ReviewsList.propType = {
    reviews: PropTypes.array.isRequired,
    page: PropTypes.number,
};


export default ReviewsList;