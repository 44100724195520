'use strict';
import "../../style/articles.css";
import React  from 'react';
import { withRouter } from 'react-router-dom';
import { RotateLoader } from 'react-spinners';
import axios from 'axios';
import ArticlesPage from '../components/articles_page';
import PaginationComponent from '../components/pagination'
import { httpRequest, queryApiUrl, log } from '../general';
import { eqProps } from 'ramda';

class Articles extends React.Component {
    constructor() {
        super();
        this.handleSelect = this.handleSelect.bind(this);
        this.state = { articles: [], totalAmount: 0, currentPage: 1, numberOfArticles: 18, fetched: false, error: false };
    }

    handleSelect(eventKey) {
        const page = parseInt(eventKey.selected);
        const link = "/articles/" + (page + 1);
        this.props.history.push(link);
        this.setState({fetched: false});
    }    

    getArticlesFromApiAsync(state, props) {
        const currentPage = props.match.params.page;
        const currentPageAsNumber = parseInt(currentPage);
        const offset = (currentPageAsNumber - 1) * state.numberOfArticles;

        var left;
        if (state.totalAmount == 0)
            left = state.numberOfArticles;
        else
            left = state.totalAmount - offset;

        const limit = left >= state.numberOfArticles ? state.numberOfArticles : left;
        const urlPath = queryApiUrl('imp_interviews/rows?sort=date&sort_order=DESC&limit=' + limit + '&offset=' + offset + '&in[published]=1');

        const self = this;
        // Make a request for a user with a given ID
        axios.get(urlPath)
        .then(function (response) {
            window.scrollTo(0, 0);
            self.setState({
                articles: response.data.data,
                totalAmount: response.data.meta.total_entries, 
                fetched: true,
                currentPage: currentPage,
             });
         })
        .catch(function (error) {
            log("Error fetching articles");
            log(error);
            self.setState({articles:[], fetched: true, error: true});
        });
    }

    shouldComponentUpdate(nextProps, nextState) {
        const currentPage = nextProps.match.params.page;
        if ( currentPage != this.props.match.params.page ) {
            this.getArticlesFromApiAsync(nextState, nextProps);
            return false; 
        }
        if (nextState.fetched == true && this.state.articles.length > 0) { 
            return true;
        } else {
            if (nextState.fetched && nextState.error) {
                return true;
            }
            this.getArticlesFromApiAsync(nextState, nextProps);
            return false;
        }
    }
    
    componentWillMount() {
        this.getArticlesFromApiAsync(this.state, this.props);
        window.scrollTo(0, 0);
    }

    render() {
        const currentPage = this.props.match.params.page;
        const currentPageAsNumber = parseInt(currentPage) -1;
        
        if (this.state.fetched == false) {
            return (
                <div className="articles-page-fixed searching">
                    <RotateLoader color="#4C0001" loading={!this.state.fetched}/>
                </div>            
            )
        }
        return (
            <div className="articles-page">
                <ArticlesPage articles={this.state.articles} page={currentPage}/>
                <PaginationComponent  
                    totalAmount={this.state.totalAmount}
                    numberOfElementsPerPage={this.state.numberOfArticles}
                    currentPage={currentPageAsNumber}
                    handleSelect={this.handleSelect} />
            </div>
        );
    }
}

export default withRouter(Articles);
