"use strict";
import "../../style/headlines.css";
import "../../style/carousel.css";
import React from 'react';
import PropTypes from 'prop-types'
import ReactImageFallback from 'react-image-fallback';
import FontAwesome from 'react-fontawesome';
import ScrollBars from 'react-custom-scrollbars';
import Dimensions from 'react-dimensions';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import { Container, Row, Col, Card,  CardHeader, CardFooter, CardBody, Media } from 'reactstrap';
import classnames from 'classnames';
import { pageLink, LeftNavButton, RightNavButton, imageWithLink, log, serverAddress, linkTo, goTo,  createRightSideReview, authorLink, paragraphRemoveImagesAndVideos, paragraphOrMaxLength, periodOrMaxLength, createDangerous, createMarkup, paragraphSkipImages, dateConverted, dateDisplayer } from '../general';

import { compose, equals, indexOf, prop, isEmpty } from 'ramda';




class HeadlinesPage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {headlines, windowWidth, containerWidth, news} = this.props;
        const to_bottom = 500;  
        const classesHeadlines = classnames('headlines', {bottom: windowWidth < to_bottom});     
        const classesHeadlinesSlider = classnames('headlines-slider', {bottom: windowWidth < to_bottom});     
        const newsFluid = classnames('news-fluid', {bottom: windowWidth < to_bottom});  
        const classesCarouselText = classnames('carousel-text', {bottom: windowWidth < to_bottom});
        const sliderWidth = windowWidth < to_bottom ? containerWidth : containerWidth / 2;
        var headlineImages = undefined;

        if (headlines != undefined) {
            headlineImages = headlines.map((headline, index) => {

                var imageFileName;
                var news_id = "/news/1/" + headline.id;
                if (headline.so_image) {
                    imageFileName =  "/images/newsimages/" + headline.so_image.trim();
                } 
                return (
                    <div className="headlines-slider-image" key={index}>
                        {imageWithLink(imageFileName, news_id, "headlines-image", "/images/default_carousel.png")}
                        {goTo(news_id, <p className="legend">{headline.topic}</p>)}
                        
                    </div>
                )
            });
        }

        const max_length_news = 9999;
        
        var newsPart = undefined;
        if (news.length > 0) {
            newsPart = news.map((new_item, index) => {
                const news_id = "news/1/" + new_item.id;
                const news_text_without = paragraphSkipImages(new_item.news);
                const news_text = paragraphRemoveImagesAndVideos(news_text_without);
                const news_text_shorted = periodOrMaxLength(news_text, 280);
                const paragraphMarkup = createMarkup(news_text_shorted);
                const news_element = createDangerous(paragraphMarkup);
                const keyCol = "news-" + index;
                const keyCard = "n-" + new_item.id;

                const headerPanel = (header, news_date) => {
                    return (
                        <div>
                            <div className="headpage-news-header">
                                <span>{header}</span>
                            </div>
                        </div>                
                    )
                }
                
                return ([
                    <div key={index} className="headpage-news"> 
                        {goTo(news_id, 
                            <Card>
                            <CardHeader>
                                {headerPanel(new_item.topic, new_item.date)}
                            </CardHeader>
                            <CardBody>
                                <div className="headpage-news-list">
                                    {news_element}
                                </div>
                            </CardBody>                            
                            </Card>     
                        )}               
                    </div>

                ]);
            });
        }
        
/*
axis	other	no	"horizontal"
className	other	no	-
autoPlay	other	no	{false}
children	other	no	-
useKeyboardArrows	other	no	{false}
showIndicators	other	no	{true}
emulateTouch	other	no	{false}
selectedItem	other	no	{0}
onClickItem	other	no	{noop()}
onClickThumb	other	no	{noop()}
onChange	other	no	{noop()}
dynamicHeight	other	no	{false}
interval	other	no	{3000}
infiniteLoop	other	no	{false}
showArrows	other	no	{true}
showStatus	other	no	{true}
showThumbs	other	no	{true}
statusFormatter	other	no	{defaultStatusFormatter()}
stopOnHover	other	no	{true}
swipeScrollTolerance	other	no	{5}
transitionTime	other	no	{350}                
*/
        const newsLink = goTo("/news/1",<label className="line-text">uutiset</label> );
        const sliderWidthPixels = sliderWidth + "px";
        return (
            <div>
                <div className="headpage-separator">
                    {newsLink}
                </div>
                <div className={classesHeadlinesSlider}>
                
                    <Carousel width={sliderWidthPixels} autoPlay={true} stopOnHover={true} showThumbs={false} showStatus={false} transitionTime={600} emulateTouch={true} interval={4000} infiniteLoop={true}>
                        {headlineImages}
                    </Carousel>
                    <Container fluid={true} className={newsFluid}>
                        <ScrollBars renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}>
                            <Row className="show-news">
                                {newsPart}
                            </Row>
                        </ScrollBars>
                    </Container>
                </div>
            </div>
        );
    }   
}

HeadlinesPage.propTypes = {
    headlines:PropTypes.array.isRequired,
    windowWidth: PropTypes.number,
    news: PropTypes.array,
};


export default Dimensions()(HeadlinesPage);
