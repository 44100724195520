"use strict";
import "../../style/card2.less";
import React from 'react';
import PropTypes from 'prop-types'
import ReactImageFallback from 'react-image-fallback';
import ReactHoverObserver from 'react-hover-observer';
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';
import { log, dateConverted, authorLink, scoreDisplayer } from '../general';
import he from 'he';
import classnames from 'classnames';


class Lappu extends React.Component {
    
    render() {
        const headerFont = classnames("icon-headpage-performer-font", {"smaller" : this.props.info.performer.length > 28});
        const headerText = this.props.info.performer ? he.decode(this.props.info.performer) : undefined;
        const recordText = this.props.info.record ? he.decode(this.props.info.record) : undefined;
        const imageFix = classnames("element-card-image", this.props.info.image_fix);

        var fallbackImageName = "default_card.png";
        if (this.props.info.interview) {
            fallbackImageName = "default_article_card.png";
        }

        const altText = headerText + " : " + recordText;
        const image = this.props.info.image == null ? undefined : <ReactImageFallback src={this.props.info.image} fallbackImage={"/images/" + fallbackImageName} className={imageFix} alt={altText}/>                    
        const hovering = this.props.info.no_hover ? true : this.props.isHovering;

        
        const scoreTag = classnames("icon-headpage-score", {"album-of-the-week":this.props.info.devil_mark});

        
        var cardStatus;
        var cardDescription;
        if (this.props.info.news == true) {            
            cardStatus = classnames("element-card", {"news": this.props.info.news});
            if (this.props.info.promote == true) {
                cardStatus = classnames(cardStatus, {"promoted": this.props.info.promote});
            }            
            cardStatus = classnames(cardStatus, {"show": hovering});
            cardDescription = classnames("element-card-description", {"news": this.props.info.news})
        } else if (this.props.info.interview == true) {
            cardStatus = classnames("element-card", {"article": this.props.info.interview});
            cardStatus = classnames(cardStatus, {"show": hovering});
            cardDescription = classnames("element-card-description", {"article": this.props.info.interview})
        } else {
            cardStatus = classnames("element-card review", {"show": hovering});
            cardDescription ="element-card-description review";
        }

        return (
            <div key={this.props.info.keyName} className={cardStatus}>
    
                {image} 
                <div key={this.props.info.keyName + "_title"} className="element-card-title">
                    <h2 className={headerFont}>
                        {headerText}
                        <small>{recordText}</small>
                    </h2>
                </div>
                <div className={cardDescription}>
                    {this.props.info.text}
                </div>
            </div>
        );                   
    }

}


class ElementCard extends React.Component {
    constructor(props, context) {
        super(props, context);
    }
              
    render() {        
        const cardKey = "card" + this.props.keyName;
        const lappuKey = "lappu" + this.props.keyName;
        var score = undefined;
        if (this.props.points) {

            score = ([<div className="score">
                <div className="icon-headpage-score">
                    <p>{scoreDisplayer(this.props.points, 9)}</p>
                </div>
            </div>])        
        }

        var albumOfTheWeek = undefined; 
        if (this.props.devil_mark == true) {
            const aotwText = <div className="aotw-text"><span>Viikon levy</span></div>
            albumOfTheWeek = <div className="aotw-div">{aotwText}</div>
        }
        
        const cardStatic = classnames("element-card-static", {"promoted": this.props.promote});
        
        return(
            <div key={cardKey} className={cardStatic}>
                <ReactHoverObserver
                    className="element-card-hover"
                    hoverDelayInMs={300}
                    hoverOffDelayInMs={200}
                >
                    <Lappu key={lappuKey} info={this.props}/>
                </ReactHoverObserver>            
                {albumOfTheWeek}
                {score}
            </div>
        );
    }
}

ElementCard.propTypes = {
    keyName: PropTypes.string,
    image: PropTypes.string,
    text: PropTypes.object,
    link: PropTypes.string,
    highlight: PropTypes.string,
    date: PropTypes.string,
    reviewer: PropTypes.object,
    no_hover: PropTypes.bool,
    promote: PropTypes.bool,
    performer: PropTypes.string,
    record: PropTypes.string,
    points: PropTypes.string,
    image_fix: PropTypes.string,   
    devilmark: PropTypes.bool,
    news: PropTypes.bool,
    interview: PropTypes.bool,
};


export default ElementCard; 

