'use strict';
import "../../style/playlists.css";
import React  from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import PlaylistDetailPage from '../components/playlist_detail_page';
import { httpRequest, queryApiUrl, log } from '../general';

class PlaylistDetail extends React.Component {
    constructor() {
        super();
        this.state = { playlist: undefined, fetched: false, error: false };
    }
    
    
    getPlaylistFromApiAsync() {
        const api = 'imp_playlists/rows/' + this.props.match.params.id + "/?";
        const urlPath = queryApiUrl(api);

        const self = this;
        // Make a request for a user with a given ID
        axios.get(urlPath)
        .then(function (response) {
            window.scrollTo(0, 0);
            self.setState({
                playlist: response.data.data, 
                fetched: true,
             });
         })
        .catch(function (error) {
            log("Error fetching playlist");
            log(error);
            self.setState({playlist: undefined, fetched: true, error: true});
        });

    }

    componentWillMount() {
        this.getPlaylistFromApiAsync();
        window.scrollTo(0, 0);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.fetched == false)
            return false;

        const isNumber = nextState.playlist instanceof Array ? false : true;
            if (nextProps.match.params.id != undefined &&  isNumber  && nextProps.match.params.id != nextState.playlist.id) {
            this.getPlaylistFromApiAsync();
            return false;
        }
                
        return true;


    }

    render() {
        if (this.state.fetched == false) {
            return null;
        }        
        return (
            <div className="playlist_detail">
                <PlaylistDetailPage playlist={this.state.playlist} location={this.props.match}/>
            </div>
        );
    }
}

PlaylistDetail.propTypes = {
    match: PropTypes.object
};

export default PlaylistDetail;
