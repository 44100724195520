"use strict";
import '../../style/playlists.css';
import React from 'react';
import PropTypes from 'prop-types'
import Dimensions from 'react-dimensions';
import { Card, Button, CardHeader, CardFooter, CardBody,
    CardTitle, CardText } from 'reactstrap';
import ShareButtons from './share_buttons';
import PublishingInfo from './publishing_info';

import { log, createDangerous, createMarkup, authorLink, bandLink, serverAddress } from '../general';

class PlaylistDetailPage extends React.Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        const {location, playlist} = this.props;
        // log(location);
        if (this.props.playlist == undefined) {
            return (
                <div>
                    <h3>Artikkelia ei löytynyt</h3>
                </div>
            );
        }

        const ownurl = serverAddress() + location.url;
        const messageToShare = "Imperiumi.netin soittolista: " + this.props.playlist.headline;

        const header = () => {
            return (
                <div>
                    <div className="playlist-detail-head">
                        <div className="playlist-name">
                            {this.props.playlist.headline}
                        </div>
                    </div>

                </div>
            );
        };
        const headerText = header(); 

        const text = createMarkup(this.props.playlist.ingress)
        const element = createDangerous(text);
        const playlistImage =  this.props.playlist.playlistimage != "" ? "/images/" + this.props.playlist.playlistimage : undefined;        

        const spotifyEmbedUrl = "https://open.spotify.com/embed/user/"+ this.props.playlist.spotifyuserid + "/playlist/" + this.props.playlist.playlistid;
        const spotifyEmbed = <iframe src={spotifyEmbedUrl} width="100%" height="600px" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>

        return (
            <div className="playlist-detail">
                <Card>
                <CardHeader className="my-card-header">
                    {headerText}
                </CardHeader>
                <CardBody>
                <div className="playlist-detail-hero">
                    <img className="playlist-image" src={playlistImage}/>
                </div>
                <ShareButtons 
                    width={this.props.containerWidth}
                    performer={this.props.headline}
                    message={messageToShare}
                    currentUrl={ownurl} 
                />                

                <div className="playlist-detail-body">
                    {element}
                    {spotifyEmbed}
                </div>
                </CardBody>
                </Card>
            </div>
        );
    }    
}

PlaylistDetailPage.propType = {
    playlist: PropTypes.object.isRequired,
    location: PropTypes.object
};

export default Dimensions()(PlaylistDetailPage);