"use strict";
import '../../style/news.css';
import React from 'react';
import PropTypes from 'prop-types'
import NewsList from './news_list';

class NewsPage extends React.Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        return (
            <NewsList news={this.props.news} page={this.props.page}/>
        );
    }    
}

NewsPage.propType = {
    news: PropTypes.array.isRequired,
    page: PropTypes.number,
};

export default NewsPage;