'use strict';
import '../../style/review.css';
import React from 'react';
import FontAwesome from 'react-fontawesome';
import { authorLink } from '../general';

const ReviewFooter = props => {

    const reviewer = authorLink(props.reviewer);    
    return (
        <div className="review-footer">
            <FontAwesome className="date-icon" name='calendar-o'/>{props.date}
            <FontAwesome className="writer-icon" name='pencil-square-o'/>{reviewer}
        </div>
    );}

export default ReviewFooter;